import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { SelectSearchComponent } from './select-search.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    SelectSearchComponent
  ],
  exports: [
    SelectSearchComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule
  ]
})
export class SelectSearchModule { }