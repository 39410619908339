import { Injectable } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { AlertService } from './../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from './../../environments/environment';
import { Tracer } from './../models/tracer';
import { Animal } from './../models/animal';
import { Fencing } from './../models/fencing';
import { Subject, Observable } from 'rxjs';    
import { ModalController, NavController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { StorageService } from './storage.service'
import { AnimalService } from './../services/animal.service';


@Injectable({
  providedIn: 'root'
})
export class FencingService {

	private _fencingListChange$ = new Subject<void>();
  	public fencingListChange$ = this._fencingListChange$.asObservable();

	fencings = new Array<Fencing>();

	constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private http: HttpClient, 
    private alertService: AlertService,
    private navCtrl: NavController,
    private animalService: AnimalService,
    public storageService: StorageService) { 
    	this.storageService.get('fencings').then(
			data => {
				if(data != null) {
					if(this.fencings.length > 0)
						return ;
					
					for(var d in data) {
						var fencing: Fencing = new Fencing();
						fencing.id = data[d].id;
						fencing.name = data[d].name;
						fencing.animals = data[d].animals;
						fencing.type = data[d].type;
						fencing.circleLat = data[d].circleLat;
						fencing.circleLon = data[d].circleLon;
						fencing.circleRadius = data[d].circleRadius;
						fencing.boxP1Lat = data[d].boxP1Lat;
						fencing.boxP1Lon = data[d].boxP1Lon;
						fencing.boxP2Lat = data[d].boxP2Lat;
						fencing.boxP2Lon = data[d].boxP2Lon;
						fencing.boxP3Lat = data[d].boxP3Lat;
						fencing.boxP3Lon = data[d].boxP3Lon;
						fencing.boxP4Lat = data[d].boxP4Lat;
						fencing.boxP4Lon = data[d].boxP4Lon;
						fencing.polyPoints = data[d].polyPoints;
						if(data[d].beepCollarwhenFencingOut)
							fencing.beepCollarwhenFencingOut = data[d].beepCollarwhenFencingOut
						this.fencings.push(fencing);
					}
				}

        this._fencingListChange$.next();
			},
			error => {
				this.fencings = [];
			}
	    );


	    this.authService.loginStatus$.subscribe((isLoggedIn) => {
	      if(isLoggedIn){
	        this.GetFencings().subscribe(() => {});
	      }
	    })

		if(this.authService.isLoggedIn)
		{
		    this.GetFencings().subscribe(() => {});
		}
	}


	GetFencings() {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.get(environment.API_URL + 'fencings/ws/', {headers : headers})
			.pipe(
				tap(data => {
					this.fencings = [];
					var newTracerId = null;
					for(var fencing in data) {
						var fencingo = new Fencing();
						fencingo.id = data[fencing]['id'];
						fencingo.name = data[fencing]['name'];
						fencingo.animals = data[fencing]['animals'];
						fencingo.type = data[fencing]['type'];
						fencingo.circleLat = data[fencing]['circleLat'];
						fencingo.circleLon = data[fencing]['circleLon'];
						fencingo.circleRadius = data[fencing]['circleRadius'];
						fencingo.boxP1Lat = data[fencing]['boxP1Lat'];
						fencingo.boxP1Lon = data[fencing]['boxP1Lon'];
						fencingo.boxP2Lat = data[fencing]['boxP2Lat'];
						fencingo.boxP2Lon = data[fencing]['boxP2Lon'];
						fencingo.boxP3Lat = data[fencing]['boxP3Lat'];
						fencingo.boxP3Lon = data[fencing]['boxP3Lon'];
						fencingo.boxP4Lat = data[fencing]['boxP4Lat'];
						fencingo.boxP4Lon = data[fencing]['boxP4Lon'];
						fencingo.polyPoints = data[fencing]['polyPoints'];
						fencingo.beepCollarwhenFencingOut = data[fencing]['beepCollarwhenFencingOut'];
						this.fencings.push(fencingo);
					}

	        		this._fencingListChange$.next();
					this.storageService.set('fencings', this.fencings);
				})
			);
		}
	}

	getFencingFromId(fencingId) {
		if(fencingId == null) {
			return null;
		}

		fencingId = parseInt(fencingId);
		for(var fencing in this.fencings) {
			if(this.fencings[fencing].id == fencingId) {
				return this.fencings[fencing];
			}
		}
		return null;
	}

	getDogsOfFencing(fencingId) {
		if(fencingId == null) {
			return [];
		}

		var fencing = this.getFencingFromId(fencingId);
		var ret = [];
		for(var animalId in fencing.animals) {
			for(var a in this.animalService.animals) {
				if(fencing.animals[animalId] == this.animalService.animals[a].id)
					ret.push(this.animalService.animals[a])
			}
		}
		return ret;
	}
}
