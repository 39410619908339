import { Injectable } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { AlertService } from './../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from './../../environments/environment';
import { Tracer } from './../models/tracer';
import { Animal } from './../models/animal';
import { Fencing } from './../models/fencing';
import { Activity, ActivityType } from './../models/activity';
import { Subject, Observable } from 'rxjs';    
import { ModalController, NavController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { StorageService } from './storage.service'
import { AnimalService } from './../services/animal.service';
import { DateUtilsService } from './../services/date-utils.service';
import * as _ from 'underscore';
import { TracerService } from './../services/tracer.service';
import { zonedTimeToUtc, utcToZonedTime, format, getTimezoneOffset } from 'date-fns-tz'

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

	private _activityListChange$ = new Subject<void>();
  public activityListChange$ = this._activityListChange$.asObservable();

	private _activityCurrentChange$ = new Subject<void>();
  public activityCurrentChange$ = this._activityCurrentChange$.asObservable();

	private _activityCurrentFinishedChange$ = new Subject<void>();
  public activityCurrentFinishedChange$ = this._activityCurrentFinishedChange$.asObservable();

	activities = new Array<Activity>();
	currentActivity: Activity = null;

	activityTypes = new Array<ActivityType>();

	private _activityTypesListChange$ = new Subject<void>();
  public activityTypesListChange$ = this._activityTypesListChange$.asObservable();

  private heartRateDatas = []

	constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private http: HttpClient, 
    private alertService: AlertService,
    private navCtrl: NavController,
    private animalService: AnimalService,
    public storageService: StorageService,
		public dateUtils: DateUtilsService,
		private tracerService: TracerService) { 

    	this.storageService.get('activities').then(
				data => {
					if(data != null)
						if(this.activities.length > 0)
							return ;

						this.activities = [];
						for(var _activity in data) {
							var activity: Activity = new Activity(data[_activity]);
							this.activities.push(activity);
						}
						this.UpdateActivitiesAnimals(false);
	  				this._activityListChange$.next();
				},
				error => {
					this.activities = [];
				}
	    );

	    this.storageService.get('activityTypes').then(
				data => {
					if(data != null)
						if(this.activityTypes.length > 0)
							return;
						
						this.activityTypes = [];
						for(var _activity in data) {
							var activity: ActivityType = new ActivityType(data[_activity]);
							this.activityTypes.push(activity);
						}
	        	this._activityTypesListChange$.next();
				},
				error => {
					this.activityTypes = [];
				}
	    );


	    this.authService.loginStatus$.subscribe((isLoggedIn) => {
	      if(isLoggedIn){
	        this.GetActivities().subscribe(() => {});
	        this.GetActivityTypes().subscribe(() => {});
	      }
	    })

	    this.animalService.animalListChange$.subscribe((isLoggedIn) => {
	       this.UpdateActivitiesAnimals(true);
	    })

	    this.animalService.animalAvatarListChange$.subscribe((isLoggedIn) => {
	       this.UpdateActivitiesAnimals(true);
	    })

			this.UpdateActivitiesAnimals(false);

		if(this.authService.isLoggedIn)
		{
		    this.GetActivities().subscribe(() => {});
	      this.GetActivityTypes().subscribe(() => {});
		}
	}


	GetActivities() {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.get(environment.API_URL + 'activities/ws/', {headers : headers})
			.pipe(
				tap((data: any[]) => {

					var hasDiff: boolean = data.length != this.activities.length;
					var activitiesSaved: Activity[] = this.activities; 
					this.activities = [];
					var newActivityId = null;
					for(var activity in data) {

						//Recherche de l'activité dans la liste précédente
						var found: boolean = false;
						var activityFound: Activity = null;
						for(var i in activitiesSaved) {
							if(data[activity]['id'] == activitiesSaved[i].id 
								&& data[activity]['name'] == activitiesSaved[i].name
								&& data[activity]['animal'] == activitiesSaved[i].animalId
								&& this.dateUtils.fromString(data[activity]['date']).getTime() == activitiesSaved[i].date.getTime()) {
									found = true;
									activityFound = activitiesSaved[i];
									break;
								}
						}
						hasDiff = hasDiff || !found;

						var activityo = new Activity();
						activityo.id = data[activity]['id'];
						activityo.name = data[activity]['name'];
						activityo.description = data[activity]['description'] != null ? data[activity]['description']: '';
						activityo.animalId = data[activity]['animal'];
						activityo.tracer = data[activity]['tracer'];
						activityo.type = data[activity]['type'];
						activityo.date = this.dateUtils.fromString(data[activity]['date']);
						activityo.duration = data[activity]['duration'];
						activityo.status = data[activity]['status'];
						activityo.stravaMustBeSync = data[activity]['stravaMustBeSync'];
						activityo.stravaIsSync = data[activity]['stravaIsSync'];
						if(activityFound) {
							activityo.animal = activityFound.animal
						}
						this.activities.push(activityo);
					}
					this.activities = _.sortBy(this.activities, function(o) { return o.date; }).reverse(); 
					this.UpdateActivitiesAnimals(true);
					
					if(hasDiff) { //Si pas de diff, on ne dit pas que la liste a changé (seul le détail peut avoir changé)
	  				this._activityListChange$.next();
					}

					this.storageService.set('activities', this.activities);
				})
			);
		}
	}

	GetActivityTypes() {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.get(environment.API_URL + 'activities/ws/GetActivityTypes', {headers : headers})
			.pipe(
				tap(data => {
					this.activityTypes = [];

					for(var activity in data['types']) {
						var a = new ActivityType();
						a.id = data['types'][activity]['id'];
						a.label = data['types'][activity]['label'];
						this.activityTypes.push(a);
					}

	        this._activityTypesListChange$.next();
					this.storageService.set('activityTypes', this.activities);
				})
			);
		}
	}

	GetCurrentActivity() {
		if(this.authService.isLoggedIn && this.tracerService.getCurrentTracer() && this.tracerService.getCurrentTracer().animal)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders(),
				'Content-Type': 'application/json'
			});

		  var json = 
			{
				'tracer_id': this.tracerService.getCurrentTracer().id,
				'animal_id': this.tracerService.getCurrentTracer().animal.id,
			};

			return this.http.post(environment.API_URL + 'activities/ws/CurrentActivity/', json, {headers : headers})
			.pipe(
				tap(data => {
					var activityo = new Activity();
					if(data['id'] == 0)
						activityo = null;
					else {
						activityo.id = data['id'];
						activityo.name = data['name'];
						activityo.description = data['description'] != null ? data['description']: '';
						activityo.animalId = data['animal'];
						activityo.animal = this.animalService.getAnimalFromId(activityo.animalId);
						activityo.tracer = data['tracer'];
						activityo.type = data['type'];
						activityo.date = this.dateUtils.fromString(data['date']);
						activityo.duration = data['duration'];
						activityo.status = data['status'];
					}
					
					this.currentActivity = activityo;
	        this._activityCurrentChange$.next();
				})
			);
		}
	}

	UpdateActivitiesAnimals(activityListChange: boolean = false) {
		var hasDiff: boolean = false;
    for(var i in this.activities)
    {
      var activity = this.activities[i];
      var newAnimal: Animal = this.getAnimalOfActivity(activity.id);

      if(newAnimal == null || activity.animal == null 
      	|| newAnimal.id != activity.animal.id || newAnimal.name != activity.animal.name 
      	|| newAnimal.iconAvatarUrl !== activity.animal.iconAvatarUrl || newAnimal.iconAvatarUrlLocal !== activity.animal.iconAvatarUrlLocal) {
      	hasDiff = true;
      }
      activity.animal = newAnimal; 
    }
    if(activityListChange && hasDiff) {
	  	this._activityListChange$.next();
    }
	}

	getActivityFromId(activityId) {
		if(activityId == null) {
			return null;
		}

		activityId = parseInt(activityId);
		for(var activity in this.activities) {
			if(this.activities[activity].id == activityId) {
				return this.activities[activity];
			}
		}
		return null;
	}

	getAnimalOfActivity(activityId) {
		if(activityId == null) {
			return null;
		}

		var activity = this.getActivityFromId(activityId);
		var ret:Animal = null;
		for(var a in this.animalService.animals) {
			if(activity.animalId == this.animalService.animals[a].id)
				ret = this.animalService.animals[a]
		}
		return ret;
	}

	start(tracerId: number, animalId: number, type: string, label: string) {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders(),
				'Content-Type': 'application/json'
			});


		  var json = 
			{
				'tracer_id': tracerId,
				'animal_id': animalId,
  			'type': type,
  			'name': label.charAt(0).toUpperCase() + label.slice(1)
			};

			return this.http.post(environment.API_URL + 'activities/ws/StartActivity/', json, {headers : headers}).pipe(
				tap(data => {
					console.log(data)
					this.heartRateDatas = []
				})
			);
		}
	}


	createFromPeriod(tracerService: TracerService, type: string, name: string) {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders(),
				'Content-Type': 'application/json'
			});

			const start = new Date(tracerService.historyDateStart.valueOf() + tracerService.historyDateStart.getTimezoneOffset() * 60 * 1000);
			const end = new Date(tracerService.historyDateEnd.valueOf() + tracerService.historyDateEnd.getTimezoneOffset() * 60 * 1000);

		  var json = 
			{
				'tracer_id': tracerService.getCurrentTracer().id,
				'animal_id': tracerService.getCurrentTracer().animal.id,
  			'type': type,
  			'name': name,
				'start': format(start, "yyyy-MM-dd'T'HH:mm:ss"),
				'end': format(end, "yyyy-MM-dd'T'HH:mm:ss"),
			};

			return this.http.post(environment.API_URL + 'activities/ws/CreateActivityFromPeriod/', json, {headers : headers}).pipe(
				tap(data => {
					console.log(data)
				})
			);
		}
	}

	finish(activityId: number) {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders(),
				'Content-Type': 'application/json'
			});

		  var json = 
			{
				'heartRateDatas': [],
			};
			for(var h in this.heartRateDatas) {
				var _h = this.heartRateDatas[h]
				var ds = this.dateUtils.toStringIso(_h['date'])
				json['heartRateDatas'].push({'date': ds, 'hr': _h['hr']})
			}

			return this.http.post(environment.API_URL + 'activities/ws/' + activityId + '/FinishActivity/', json, {headers : headers}).pipe(
				tap(data => {
        	this._activityCurrentFinishedChange$.next();
					console.log(data)
				})
			);
		}
	}

	getGPX(activityId) {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});

			return this.http.get(environment.API_URL + 'activities/ws/' + activityId + '/GetGPX/', {headers : headers}).pipe(
				tap(data => {
					//console.log(data);
				})
			);
		}
	}

	getStatslive(activityId) {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});

			return this.http.get(environment.API_URL + 'activities/ws/' + activityId + '/GetStatsLive/', {headers : headers}).pipe(
				tap(data => {
					//console.log(data);
				})
			);
		}
	}

	addHeartRateData(date, hr) {
		this.heartRateDatas.push({'date': date, 'hr': hr})
	}


}
