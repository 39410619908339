import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Configuration } from './../models/configuration';
import { Subject, Observable } from 'rxjs';    

@Injectable({
  providedIn: 'root'
})
export class StorageService {
	private _storage: Storage | null = null;
  public configuration: Configuration;

  public _configurationUpdated$ = new Subject<void>();
  public configurationUpdated$ = this._configurationUpdated$.asObservable();

  constructor(private storage: Storage) {
  	this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    this._storage = await this.storage.create();

    this.get('configuration').then(
      data => {
        this.configuration = data;
        if(this.configuration == null)
          this.configuration = new Configuration();
        this._configurationUpdated$.next();
      },
      error => {
        this.configuration = new Configuration();
        this._configurationUpdated$.next();
      });
  }

  public set(key: string, value: any) {
  	if(this._storage != null)
    	this._storage.set(key, value);
    else
    	console.log('_storage is null');
  }

  public get(key: string) {
	return this.storage.get(key).then(token => {
        //console.log('Got token', token);
        return token;
    }, error => console.error(error));
  }

  public remove(key: string) {
  	if(this._storage != null)
    	return this._storage.remove(key);
    else {
    	console.log('_storage is null');
    	return null;
    }
  }

  public saveConfiguration() {
    this.set('configuration', this.configuration);
    this._configurationUpdated$.next();
  }
}
