import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { parse, parseISO, differenceInSeconds, addDays, addHours, getDay } from 'date-fns'
import { zonedTimeToUtc, utcToZonedTime, format, getTimezoneOffset } from 'date-fns-tz'
import { fr } from 'date-fns/locale';


export class ActivityType {
    id: string;
    label: string;
    
    constructor(obj = null) {
        obj && Object.assign(this, obj);
    }
}

export class Activity {
    id: number;
    name: string;
    description: string;
    date: Date;
    animalId: number;
    duration: number;
    type: string;
    status: string;
    tracer: number;
    animal;
    stravaMustBeSync: boolean;
    stravaIsSync: boolean;

    constructor(obj = null) {
        obj && Object.assign(this, obj);
    }

    dateToString() {
        if(this.date == null)
            return '';
        return format(this.date, 'dd/MM/yyyy', {locale: fr});
    }
}
