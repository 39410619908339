import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { parse, parseISO, differenceInSeconds, addDays, addHours, getDay, fromUnixTime } from 'date-fns'
import { da, de, enGB, es, fi, fr, it, nl, pt, sv } from 'date-fns/locale';

import { zonedTimeToUtc, utcToZonedTime, format, getTimezoneOffset } from 'date-fns-tz'

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  constructor(
    private translate: TranslateService,) { 
  }

  getLocale(code: String) {
    if(code == 'da')
      return da;
    if(code == 'de')
      return de;
    if(code == 'en')
      return enGB;
    if(code == 'es')
      return es;
    if(code == 'fi')
      return fi;
    if(code == 'fr')
      return fr;
    if(code == 'it')
      return it;
    if(code == 'nl')
      return nl;
    if(code == 'pt')
      return pt;
    if(code == 'sv')
      return sv;
    return enGB;
  }

  toString(date: Date) {
    if(date == null)
      return '';
    return format(date, 'dd/MM/yyyy HH:mm:ss', {locale: this.getLocale(this.translate.currentLang)});
  }

  toStringTime(date: Date) {
    if(date == null)
      return '';
    return format(date, 'HH:mm:ss', {locale: this.getLocale(this.translate.currentLang)});
  }

  toStringWithoutTime(date: Date) {
    if(date == null)
      return '';
    return format(date, 'dd/MM/yyyy', {locale: this.getLocale(this.translate.currentLang)});
  }

  toStringIso(date: Date, locale = fr) {
    if(date == null)
      return '';
    return format(date, "yyyy-MM-dd'T'HH:mm:ssXXXXX", {locale: this.getLocale(this.translate.currentLang)});
  }

  toStringIsoWithoutTimezone(date: Date, locale = fr) {
    if(date == null)
      return '';
    return format(date, "yyyy-MM-dd'T'HH:mm:ss", {locale: this.getLocale(this.translate.currentLang)});
  }

  toStringIsoWithoutTimezoneUTC(date: Date, locale = fr) {
    if(date == null)
      return '';
    return format(date, "yyyy-MM-dd'T'HH:mm:ss", { timeZone: 'UTC' });
  }

  toStringIsoWithoutTime(date: Date, locale = fr) {
    if(date == null)
      return '';
    return format(date, "yyyy-MM-dd", {locale: this.getLocale(this.translate.currentLang)});
  }

  fromString(date: string) {
    return date ? utcToZonedTime(parseISO(date), Intl.DateTimeFormat().resolvedOptions().timeZone): null;
  }

  fromTimestamp(date: number) {
    return date ? utcToZonedTime(fromUnixTime(date), Intl.DateTimeFormat().resolvedOptions().timeZone): null;
  }

  toStringDiff(date: Date) {
    var current = new Date();
    var difference = current.getTime() - date.getTime();
    var resultInMinutes = Math.round(difference / 60000);
    if(resultInMinutes < 1) {
      return '<span class="timeUpdateGreen">< 1 min</span>'
    }
    if(resultInMinutes < 5) {
      return '<span class="timeUpdateGreen">' + resultInMinutes +' min</span>'
    }
    if(resultInMinutes < 30) {
      return '<span class="timeUpdateOrange">' + resultInMinutes +' min</span>'
    }
    if(resultInMinutes < 60) {
      return '<span class="timeUpdateRed">' + resultInMinutes +' min</span>'
    }
    if(resultInMinutes < 120) {
      return '<span class="timeUpdateRed">> 1 h</span>'
    }
    if(resultInMinutes < 180) {
      return '<span class="timeUpdateRed">> 2 h</span>'
    }
    if(resultInMinutes < 240) {
      return '<span class="timeUpdateRed">> 3 h</span>'
    }
    return '<span class="timeUpdateRed">> 4 h</span>'
  }

  toStringShortWithoutTimeWithDay(date: Date, locale = fr) {
    if(date == null)
      return '';
    return format(date, 'd LLL yyyy', {locale: this.getLocale(this.translate.currentLang)});
  }

  toStringWithoutTimeWithDay(date: Date, locale = fr) {
    if(date == null)
      return '';
    return format(date, 'EEEE d LLL yyyy', {locale: this.getLocale(this.translate.currentLang)});
  }

  toStringShortWithTimeWithDay(date: Date, locale = fr) {
    if(date == null)
      return '';

    return format(date, 'd LLL yyyy HH:mm', {locale: this.getLocale(this.translate.currentLang)});
  }

  toStringMonthYear(date: Date, locale = fr) {
    if(date == null)
      return '';
    return format(date, 'LLLL yyyy', {locale: this.getLocale(this.translate.currentLang)});
  }

  toStringDay(date: Date, locale = fr) {
    if(date == null)
      return '';
    return format(date, 'EEEE', {locale: this.getLocale(this.translate.currentLang)});
  }


}
