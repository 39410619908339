
export var environment = {
  production: true,
  API_URL_CLEAR: 'https://server-dev.canigps.fr/',
  API_URL: 'https://server-dev.canigps.fr/',
  API_URL_STATIC: 'https://staticserver.canigps.fr',
  API_URL_MEDIA: '',
  API_GPXConsumer : 'wss://server-dev.canigps.fr/en/websocket/gpxconsumer/',
  STRAVA_CLIENT_ID: '100662',
  STRAVA_WEB_redirectUrl: 'http://localhost:8100',
};