import { Injectable } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { AlertService } from './../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from './../../environments/environment';
import { Tracer, TracerSubscription } from './../models/tracer';
import { Animal } from './../models/animal';
import { Subject, Observable } from 'rxjs';    
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { StorageService } from './storage.service'
import { parse, parseISO, differenceInSeconds, addDays, addHours, getDay, formatISO } from 'date-fns'
import { fr } from 'date-fns/locale';
import { zonedTimeToUtc, utcToZonedTime, format, getTimezoneOffset } from 'date-fns-tz'
import { DateUtilsService } from './../services/date-utils.service';

@Injectable({
  providedIn: 'root'
})
export class TracerService {

	public _tracerListChange$ = new Subject<void>();
  public tracerListChange$ = this._tracerListChange$.asObservable();
	public _tracerAvatarChange$ = new Subject<void>();
  public tracerAvatarChange$ = this._tracerAvatarChange$.asObservable();
	public _tracerAvatarListChange$ = new Subject<void>();
  public tracerAvatarListChange$ = this._tracerAvatarListChange$.asObservable();
	public _tracerGPXChange$ = new Subject<void>();
  public tracerGPXChange$ = this._tracerGPXChange$.asObservable();
	public _tracerCurrentChange$ = new Subject<void>();
  public tracerCurrentChange$ = this._tracerCurrentChange$.asObservable();

	public _tracerModeLiveProgressChange$ = new Subject<void>();
  public tracerModeLiveProgressChange$ = this._tracerModeLiveProgressChange$.asObservable();
	public _tracerModeActivityProgressChange$ = new Subject<void>();
  public tracerModeActivityProgressChange$ = this._tracerModeActivityProgressChange$.asObservable();
	public _tracerModeStandByProgressChange$ = new Subject<void>();
  public tracerModeStandByProgressChange$ = this._tracerModeStandByProgressChange$.asObservable();
	public _tracerModeSleepProgressChange$ = new Subject<void>();
  public tracerModeSleepProgressChange$ = this._tracerModeSleepProgressChange$.asObservable();

	public _tracerWakeUpStartChange$ = new Subject<void>();
  public tracerWakeUpStartChange$ = this._tracerWakeUpStartChange$.asObservable();

	public _tracerHistoryChange$ = new Subject<void>();
  public tracerHistoryChange$ = this._tracerHistoryChange$.asObservable();

	tracers = new Array<Tracer>();
	tracersIsLoading = false;
	currentTracerId = null;
	setModeStartTime: Date = null;

	historyIsActivate: boolean = false;
	historyDateStart: Date = null;
	historyDateEnd: Date = null;

	constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private http: HttpClient, 
    private alertService: AlertService,
    private navCtrl: NavController,
    public storageService: StorageService,
		public dateUtils: DateUtilsService,
    public alertController: AlertController) { 

			this.historyInitDate();


    	this.storageService.get('tracers').then(
			data => {
				if(data != null && this.tracers.length == 0) {
					this.tracersIsLoading = true;
					for(var d in data) {
						var tracer: Tracer = new Tracer();
						tracer.id = data[d].id;
						tracer.code = data[d].code;
						tracer.animalId = data[d].animalId;
						tracer.animal = data[d].animal;
						tracer.iconAvatarRawUrl = data[d].iconAvatarRawUrl;
						tracer.iconAvatarRawUrlLocal = data[d].iconAvatarRawUrlLocal ? data[d].iconAvatarRawUrlLocal: null;
						tracer.iconAvatarRawUrlLocalRaw = data[d].iconAvatarRawUrlLocalRaw ? data[d].iconAvatarRawUrlLocalRaw: null;
						tracer.iconAvatarUrl = data[d].iconAvatarUrl;
						tracer.iconAvatarUrlLocal = data[d].iconAvatarUrlLocal ? data[d].iconAvatarUrlLocal: null;
						tracer.iconAvatarUrlLocalRaw = data[d].iconAvatarUrlLocalRaw ? data[d].iconAvatarUrlLocalRaw: null;
						tracer.iconMapUrlLocal = data[d].iconMapUrlLocal ? data[d].iconMapUrlLocal: null;
						tracer.iconMapUrlLocalRaw = data[d].iconMapUrlLocalRaw ? data[d].iconMapUrlLocalRaw: null;
						tracer.iconMapErrorUrlLocal = data[d].iconMapErrorUrlLocal ? data[d].iconMapErrorUrlLocal: null;
						tracer.iconMapErrorUrlLocalRaw = data[d].iconMapErrorUrlLocalRaw ? data[d].iconMapErrorUrlLocalRaw: null;
						tracer.iconAvatarLoadedIs = data[d].iconAvatarLoadedIs ? data[d].iconAvatarLoadedIs: true;
						tracer.iconMapLoadedIs = data[d].iconMapLoadedIs ? data[d].iconMapLoadedIs: true;
						tracer.iconMapErrorLoadedIs = data[d].iconMapErrorLoadedIs ? data[d].iconMapErrorLoadedIs: true;
						tracer.iconAvatarBase64 = data[d].iconAvatarBase64;
						tracer.latestPosition = data[d].latestPosition;
						tracer.latestPositionDate = data[d].latestPositionDate;
						tracer.latestAltitude = data[d].latestAltitude;
						tracer.latestPing = data[d].latestPing;
						tracer.latestAccuracy = data[d].latestAccuracy ? data[d].latestAccuracy: 0;
						tracer.positions = data[d].positions;
						tracer.iconMapUrl = data[d].iconMapUrl;
						tracer.iconMapBase64 = data[d].iconMapBase64;
						tracer.iconMapErrorUrl = data[d].iconMapErrorUrl;
						tracer.iconMapErrorBase64 = data[d].iconMapErrorBase64;
						if(data[d].voltageLevel)
						{
							tracer.voltageLevel = data[d].voltageLevel;
							tracer.gsmStatus = data[d].gsmStatus;
							tracer.GPSTrackingIsOn = data[d].GPSTrackingIsOn;
							tracer.ChargeIsOn = data[d].ChargeIsOn;
							tracer.ActivatedIs = data[d].ActivatedIs;
							tracer.uploadIntervalSec = data[d].uploadIntervalSec;
							tracer.uploadPeriod = data[d].uploadPeriod;
						}
						if(data[d].networkType)
							tracer.networkType = data[d].networkType
						if(data[d].GPSQualityIsBad)
							tracer.GPSQualityIsBad = data[d].GPSQualityIsBad

						tracer.sleep1IsEnable = data[d].sleep1IsEnable
						tracer.sleep1TimeStart = data[d].sleep1TimeStart
						tracer.sleep1TimeEnd = data[d].sleep1TimeEnd
						tracer.sleep2IsEnable = data[d].sleep2IsEnable
						tracer.sleep2TimeStart = data[d].sleep2TimeStart
						tracer.sleep2TimeEnd = data[d].sleep2TimeEnd
						tracer.sleep3IsEnable = data[d].sleep3IsEnable
						tracer.sleep3TimeStart = data[d].sleep3TimeStart
						tracer.sleep3TimeEnd = data[d].sleep3TimeEnd

						if(data[d].subscriptionLatest)
						{
							tracer.subscriptionLatest = new TracerSubscription()
							tracer.subscriptionLatest.id = data[d].subscriptionLatest.id;
							tracer.subscriptionLatest.tracerId = data[d].subscriptionLatest.tracerId;
							tracer.subscriptionLatest.start = data[d].subscriptionLatest.start;
							tracer.subscriptionLatest.durationDays = data[d].subscriptionLatest.durationDays;
							tracer.subscriptionLatest.isActivated = data[d].subscriptionLatest.isActivated;
						}

						tracer.confAlertVoltageNotif = data[d].confAlertVoltageNotif ? data[d].confAlertVoltageNotif: true;
						tracer.confAlertVoltageEmail = data[d].confAlertVoltageEmail ? data[d].confAlertVoltageEmail: false;
						tracer.confAlertFencingNotif = data[d].confAlertFencingNotif ? data[d].confAlertFencingNotif: true;
						tracer.confAlertFencingEmail = data[d].confAlertFencingEmail ? data[d].confAlertFencingEmail: false;
						tracer.confAlertCountryNotif = data[d].confAlertCountryNotif ? data[d].confAlertCountryNotif: true;
						tracer.confAlertCountryEmail = data[d].confAlertCountryEmail ? data[d].confAlertCountryEmail: false;
						tracer.isHidden = data[d].isHidden ? data[d].isHidden: false;

						tracer.defaultActivityType = data[d].defaultActivityType ? data[d].defaultActivityType: 'walk';

						tracer.model = data[d].model ? data[d].model: '';
						tracer.isSubscriptionUpdated = data[d].isSubscriptionUpdated ? data[d].isSubscriptionUpdated: false;
						tracer.displayPopupComment = data[d].displayPopupComment ? data[d].displayPopupComment: false;
						tracer.isConnected = data[d].isConnected ? data[d].isConnected: false;
						tracer.isSIMActivate = data[d].isSIMActivate ? data[d].isSIMActivate: true;
						tracer.isShare = data[d].isShare ? data[d].isShare: false;
						tracer.shareWithUsers = data[d].shareWithUsers ? data[d].shareWithUsers: [];

						this.tracers.push(tracer);
					}

					this.tracersIsLoading = false;
				}

				this.storageService.get('currentTracerId').then(
				data => {
					this.currentTracerId = data;
	        this._tracerListChange$.next();
					this._tracerCurrentChange$.next();
				},
				error => {
				});

			},
			error => {
				this.clearTracersArray();
				this.currentTracerId = null;
			});

	    this.authService.loginStatus$.subscribe((isLoggedIn) => {
	      if(isLoggedIn){
	        //this.GetTracers().subscribe(() => {});
	      } else {
	      	this.clearTracersArray();
					this.currentTracerId = null;
	      }
	    })
	    this.tracerAvatarListChange$.subscribe((isLoggedIn) => {
				this.SaveTracers();
	    })

	    this.tracerAvatarChange$.subscribe((isLoggedIn) => {
	    	if(this.tracersIsLoading)
	    		return;

	    	var allAvatarsUpdate: boolean = true;
				for(var tracer in this.tracers) {
					if(!this.tracers[tracer].iconAvatarRawLoadedIs || !this.tracers[tracer].iconAvatarLoadedIs || !this.tracers[tracer].iconMapLoadedIs || !this.tracers[tracer].iconMapErrorLoadedIs) {
						allAvatarsUpdate = false;
						break;
					}
				}

				if(allAvatarsUpdate) {
					this._tracerListChange$.next();
					this._tracerAvatarListChange$.next();
				}
	    })
		
		if(this.authService.isLoggedIn)
		{
		    this.GetTracers().subscribe(() => {});
		}
	}

	clearTracersArray() {
		for(var tracer of this.tracers) {
			tracer.iconAvatarRawBase64 = null
			tracer.iconAvatarBase64 = null
			tracer.iconMapBase64 = null
			tracer.iconMapErrorBase64 = null
		}
		this.tracers =[]
	}


	historyActivate(dateStart: Date, dateEnd: Date) {
		this.historyIsActivate = true;
		this.historyDateStart = dateStart;
		this.historyDateEnd = dateEnd;
		this._tracerHistoryChange$.next();

		this.updateGPX();
	}

	historyDeactivate() {
		this.historyIsActivate = false;
		this.historyInitDate();
		this._tracerHistoryChange$.next();

		this.updateGPX();
	}

	historyInitDate() {
    var d = new Date();
    d.setHours(d.getHours() - 6);
    d.setMinutes(0);
    d.setSeconds(0);
    this.historyDateStart = d;

    d = new Date();
    d.setHours(d.getHours() + 1);
    d.setMinutes(0);
    d.setSeconds(0);
    this.historyDateEnd = d;
	}

	EmptyTracers() {
		this.clearTracersArray();
		this._tracerListChange$.next();
		this._tracerCurrentChange$.next();
	}

	SetIsSubscriptionUpdatedToFalse(tracer) {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.get(environment.API_URL + 'tracers/ws/' + tracer.id + '/SetIsSubscriptionUpdatedToFalse', {headers : headers})
			.pipe(
			tap(data => {
			})).subscribe();
		}
	}

	GetTracers() {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.get(environment.API_URL + 'tracers/ws/', {headers : headers})
			.pipe(
				tap(data => {
					var tracersSave = this.tracers;

					this.tracersIsLoading = true;
					this.clearTracersArray();
					var newTracerId = null;
					for(var tracer in data) {
						var tracero = new Tracer();

						tracero.id = data[tracer]['id'];
						tracero.code = data[tracer]['code'];
						tracero.animalId = data[tracer]['animalId']
						
						tracero.voltageLevel = data[tracer]['voltageLevel'];
						tracero.gsmStatus = data[tracer]['gsmStatus'];
						tracero.GPSTrackingIsOn = data[tracer]['GPSTrackingIsOn'];
						tracero.uploadIntervalSec = data[tracer]['uploadIntervalSec'];
						tracero.uploadPeriod = data[tracer]['uploadPeriod'];
						tracero.ChargeIsOn = data[tracer]['ChargeIsOn'];
						tracero.ActivatedIs = data[tracer]['ActivatedIs'];
						tracero.latestPosition = data[tracer]['latestPosition'];
						tracero.latestPositionDate = this.dateUtils.fromString(data[tracer]['latestPositionDate']);
						tracero.latestAltitude = data[tracer]['latestAltitude'];
						tracero.latestAccuracy = data[tracer]['latestAccuracy'];
						tracero.latestPing = this.dateUtils.fromString(data[tracer]['latestPing']);

						for(var _tracer in tracersSave) {
							if(tracersSave[_tracer].id == tracero.id) {
								if(tracersSave[_tracer].iconAvatarRawUrl != null && tracersSave[_tracer].iconAvatarUrl != null && tracersSave[_tracer].iconMapUrl != null && tracersSave[_tracer].iconMapErrorUrl != null) {
									tracero.iconAvatarRawUrl =	tracersSave[_tracer].iconAvatarRawUrl;
									tracero.iconAvatarRawUrlLocal =	tracersSave[_tracer].iconAvatarRawUrlLocal;
									tracero.iconAvatarRawUrlLocalRaw =	tracersSave[_tracer].iconAvatarRawUrlLocalRaw;
									tracero.iconAvatarRawBase64 =	tracersSave[_tracer].iconAvatarRawBase64;

									tracero.iconAvatarUrl =	tracersSave[_tracer].iconAvatarUrl;
									tracero.iconAvatarUrlLocal =	tracersSave[_tracer].iconAvatarUrlLocal;
									tracero.iconAvatarUrlLocalRaw =	tracersSave[_tracer].iconAvatarUrlLocalRaw;
									tracero.iconAvatarBase64 =	tracersSave[_tracer].iconAvatarBase64;
									tracero.iconMapUrl =	tracersSave[_tracer].iconMapUrl;
									tracero.iconMapUrlLocal =	tracersSave[_tracer].iconMapUrlLocal;
									tracero.iconMapUrlLocalRaw =	tracersSave[_tracer].iconMapUrlLocalRaw;
									tracero.iconMapBase64 =	tracersSave[_tracer].iconMapBase64;
									tracero.iconMapErrorUrl =	tracersSave[_tracer].iconMapErrorUrl;
									tracero.iconMapErrorUrlLocal =	tracersSave[_tracer].iconMapErrorUrlLocal;
									tracero.iconMapErrorUrlLocalRaw =	tracersSave[_tracer].iconMapErrorUrlLocalRaw;
									tracero.iconMapErrorBase64 =	tracersSave[_tracer].iconMapErrorBase64;
								}
							}
						}
						tracero.setIconAvatar(this, this.http, environment.API_URL_MEDIA + data[tracer]['iconAvatar']);
						tracero.setIconAvatarRaw(this, this.http, environment.API_URL_MEDIA + data[tracer]['iconAvatarRaw']);
						tracero.setIconMap(this, this.http, environment.API_URL_MEDIA + data[tracer]['iconMap']);
						tracero.setIconMapError(this, this.http, environment.API_URL_MEDIA + data[tracer]['iconMapError']);

						tracero.sleep1IsEnable = data[tracer]['sleep1IsEnable'];
						tracero.sleep1TimeStart = data[tracer]['sleep1TimeStart'];
						tracero.sleep1TimeEnd = data[tracer]['sleep1TimeEnd'];
						tracero.sleep2IsEnable = data[tracer]['sleep2IsEnable'];
						tracero.sleep2TimeStart = data[tracer]['sleep2TimeStart'];
						tracero.sleep2TimeEnd = data[tracer]['sleep2TimeEnd'];
						tracero.sleep3IsEnable = data[tracer]['sleep3IsEnable'];
						tracero.sleep3TimeStart = data[tracer]['sleep3TimeStart'];
						tracero.sleep3TimeEnd = data[tracer]['sleep3TimeEnd'];

						if(data[tracer]['subscriptionLatest'])
						{	
							tracero.subscriptionLatest = new TracerSubscription();
							tracero.subscriptionLatest.id = data[tracer]['subscriptionLatest']['id'];
							tracero.subscriptionLatest.tracerId = data[tracer]['subscriptionLatest']['tracer_id'];
							tracero.subscriptionLatest.start = this.dateUtils.fromString(data[tracer]['subscriptionLatest']['start']);
							tracero.subscriptionLatest.durationDays = data[tracer]['subscriptionLatest']['durationDays'];
							tracero.subscriptionLatest.isActivated = data[tracer]['subscriptionLatest']['isActivated'];
						}

						tracero.confAlertVoltageNotif = data[tracer]['confAlertVoltageNotif'];
						tracero.confAlertVoltageEmail = data[tracer]['confAlertVoltageEmail'];
						tracero.confAlertFencingNotif = data[tracer]['confAlertFencingNotif'];
						tracero.confAlertFencingEmail = data[tracer]['confAlertFencingEmail'];
						tracero.confAlertCountryNotif = data[tracer]['confAlertCountryNotif'];
						tracero.confAlertCountryEmail = data[tracer]['confAlertCountryEmail'];
						tracero.isHidden = data[tracer]['isHidden'];

						tracero.model = data[tracer]['model'];
						if(data[tracer]['defaultActivityType'])
							tracero.defaultActivityType = data[tracer]['defaultActivityType'];

						if(data[tracer]['networkType'])
							tracero.networkType = data[tracer]['networkType']
						
						if(data[tracer]['GPSQualityIsBad'] != null)
							tracero.GPSQualityIsBad = data[tracer]['GPSQualityIsBad']

						if(data[tracer]['isConnected'] != null)
							tracero.isConnected = data[tracer]['isConnected']
						
						if(data[tracer]['isSIMActivate'] != null)
							tracero.isSIMActivate = data[tracer]['isSIMActivate']

						if(data[tracer]['isShare'] != null)
							tracero.isShare = data[tracer]['isShare']
						
						if(data[tracer]['shareWithUsers'] != null)
							tracero.shareWithUsers = data[tracer]['shareWithUsers']

						tracero.isSubscriptionUpdated = data[tracer]['isSubscriptionUpdated'];
						
						this.tracers.push(tracero);

						if(data[tracer]['isSelected'])
						{
							newTracerId = tracero.id;
						}
					}

					var currentTracerIdSave = this.currentTracerId;
      		if(newTracerId != null)
      		{
						this.currentTracerId = newTracerId;
					}
					if(this.currentTracerId == null) {
						for(var t in this.tracers) {
							this.currentTracerId = this.tracers[t].id;
							break;
						}
					}

					this.tracersIsLoading = false;

					if(currentTracerIdSave != this.currentTracerId)
						this._tracerCurrentChange$.next();
					this._tracerAvatarChange$.next();

					this.SaveTracers(false);
				})
			);
		}
	}

	SaveTracers(saveCurrentTracerId = true)
	{
		this.storageService.set('tracers', this.tracers);
		if(saveCurrentTracerId)
			this.storageService.set('currentTracerId', this.currentTracerId);
	}

	getTracerFromId(tracerId) {
		if(tracerId == null) {
			return null;
		}

		tracerId = parseInt(tracerId);
		for(var tracer in this.tracers) {
			if(this.tracers[tracer].id == tracerId) {
				return this.tracers[tracer];
			}
		}
		return null;
	}

	getCurrentTracer() {
		if(this.currentTracerId == null)
			return null;

		for(var tracer in this.tracers) {
			if(this.tracers[tracer].id == this.currentTracerId) {
				return this.tracers[tracer];
			}
		}
		return null;
	}

	selectTracker(trackerId) {
		this.currentTracerId = trackerId;
		var tracker = this.getCurrentTracer();


		if(tracker != null) {
			this._tracerCurrentChange$.next();

			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			this.http.get(environment.API_URL + 'tracers/ws/' + trackerId + '/Select/', {headers : headers}).subscribe(data => {});
		}

		this.SaveTracers();
	}

	updateGPX() {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});

			if(!this.historyIsActivate)
				this.historyInitDate();

			const start = new Date(this.historyDateStart.valueOf() + this.historyDateStart.getTimezoneOffset() * 60 * 1000);
			const end = new Date(this.historyDateEnd.valueOf() + this.historyDateEnd.getTimezoneOffset() * 60 * 1000);

			var json = 
			{
				'start': format(start, "yyyy-MM-dd'T'HH:mm:ss"),
				'end': format(end, "yyyy-MM-dd'T'HH:mm:ss"),
			};

			this.http.post(environment.API_URL + 'tracers/ws/GetTracers/', json, {headers : headers}).subscribe(data => {
				for(var gpx in data) {
					for(var _tracer in this.tracers)
					{
						var tracer:Tracer = this.tracers[_tracer];
						if(tracer.id == data[gpx]['id'])
						{
					    tracer.latestPosition = data[gpx]['latestPosition'];
					    tracer.latestPositionDate = this.dateUtils.fromString(data[gpx]['latestPositionDate']);
							tracer.latestAltitude = data[gpx]['latestAltitude'];
					    tracer.latestPing = this.dateUtils.fromString(data[gpx]['latestPing']);
					    tracer.latestAccuracy = data[gpx]['latestAccuracy'];
							tracer.isSubscriptionUpdated = data[gpx]['isSubscriptionUpdated'];
							tracer.displayPopupComment = data[gpx]['displayPopupComment'];
							tracer.isConnected = data[gpx]['isConnected']
							tracer.positions = data[gpx]['trace']['positions'];

							if(data[gpx]['isSIMActivate'] != null)
								tracer.isSIMActivate = data[gpx]['isSIMActivate']

							if(data[gpx]['isShare'] != null)
								tracer.isShare = data[gpx]['isShare']

							if(data[gpx]['shareWithUsers'] != null)
								tracer.shareWithUsers = data[gpx]['shareWithUsers']

							if(data[gpx]['subscriptionLatest'] != null) {
								tracer.subscriptionLatest = new TracerSubscription();
								tracer.subscriptionLatest.id = data[gpx]['subscriptionLatest']['id'];
								tracer.subscriptionLatest.tracerId = data[gpx]['subscriptionLatest']['tracer_id'];
								tracer.subscriptionLatest.start = this.dateUtils.fromString(data[gpx]['subscriptionLatest']['start']);
								tracer.subscriptionLatest.durationDays = data[gpx]['subscriptionLatest']['durationDays'];
								tracer.subscriptionLatest.isActivated = data[gpx]['subscriptionLatest']['isActivated'];
							}
							else
								tracer.subscriptionLatest = null;
							
						}
					}
				}
        this._tracerGPXChange$.next();
			});
		}
	}

	updateGPXFromShare(shareCode: string) {
	  var json = 
		{
			'share': shareCode,
		};

		this.http.post(environment.API_URL + 'tracers/ws_share/GetTracerTraceWithStats/', json, {}).subscribe(data => {
			this.tracersIsLoading = true;
			this.clearTracersArray();

			var currentTracerIdSave = this.currentTracerId;
			for(var tracer in data) {
				var tracero = new Tracer();

				tracero.id = data[tracer]['id'];
				tracero.code = data[tracer]['code'];
				tracero.animalId = data[tracer]['animalId']
				
				tracero.voltageLevel = data[tracer]['voltageLevel'];
				tracero.gsmStatus = data[tracer]['gsmStatus'];
				tracero.GPSTrackingIsOn = data[tracer]['GPSTrackingIsOn'];
				tracero.uploadIntervalSec = data[tracer]['uploadIntervalSec'];
				tracero.uploadPeriod = data[tracer]['uploadPeriod'];
				tracero.ChargeIsOn = data[tracer]['ChargeIsOn'];
				tracero.ActivatedIs = data[tracer]['ActivatedIs'];
				tracero.latestPosition = data[tracer]['latestPosition'];
				tracero.latestPositionDate = this.dateUtils.fromString(data[tracer]['latestPositionDate']);
				tracero.latestAltitude = data[tracer]['latestAltitude'];
				tracero.latestPing = this.dateUtils.fromString(data[tracer]['latestPing']);

				tracero.setIconAvatar(this, this.http, environment.API_URL_MEDIA + data[tracer]['iconAvatar']);
				tracero.setIconAvatarRaw(this, this.http, environment.API_URL_MEDIA + data[tracer]['iconAvatarRaw']);

				tracero.positions = data[tracer]['trace']['positions'];
		    tracero.latestPosition = data[tracer]['latestPosition'];
		    tracero.latestPositionDate = this.dateUtils.fromString(data[tracer]['latestPositionDate']);
				tracero.latestAltitude = data[tracer]['latestAltitude'];
		    tracero.latestPing = this.dateUtils.fromString(data[tracer]['latestPing']);
				tracero.setIconMap(this, this.http, environment.API_URL_MEDIA + data[tracer]['iconMap']);
				tracero.setIconMapError(this, this.http, environment.API_URL_MEDIA + data[tracer]['iconMapError']);

				if(data[tracer]['subscriptionLatest'] != null) {
					tracero.subscriptionLatest = new TracerSubscription();
					tracero.subscriptionLatest.id = data[tracer]['subscriptionLatest']['id'];
					tracero.subscriptionLatest.tracerId = data[tracer]['subscriptionLatest']['tracer_id'];
					tracero.subscriptionLatest.start = this.dateUtils.fromString(data[tracer]['subscriptionLatest']['start']);
					tracero.subscriptionLatest.durationDays = data[tracer]['subscriptionLatest']['durationDays'];
					tracero.subscriptionLatest.isActivated = data[tracer]['subscriptionLatest']['isActivated'];
				}
				else
					tracero.subscriptionLatest = null;

				if(data[tracer]['animal'] != null) {
					tracero.animal = new Animal();
					tracero.animal.name = data[tracer]['dogName'];
					tracero.animal.id = data[tracer]['id'];
				}

				if(data[tracer]['networkType'])
					tracero.networkType = data[tracer]['networkType']
				if(data[tracer]['GPSQualityIsBad'])
					tracero.GPSQualityIsBad = data[tracer]['GPSQualityIsBad']

				tracero.shareStart = this.dateUtils.fromString(data[tracer]['share']['start'])
				tracero.shareEnd = this.dateUtils.fromString(data[tracer]['share']['end'])

				this.tracers.push(tracero);

				this.currentTracerId = tracero.id;
			}
			
			this.tracersIsLoading = false;
			if(currentTracerIdSave != this.currentTracerId)
				this._tracerCurrentChange$.next();
      this._tracerGPXChange$.next();
			this._tracerAvatarChange$.next();
		});
	}

	getGPXWithStat(tracerId: number) {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});

			if(!this.historyIsActivate)
				this.historyInitDate();

			const start = new Date(this.historyDateStart.valueOf() + this.historyDateStart.getTimezoneOffset() * 60 * 1000);
			const end = new Date(this.historyDateEnd.valueOf() + this.historyDateEnd.getTimezoneOffset() * 60 * 1000);

			var json = 
			{
				'start': format(start, "yyyy-MM-dd'T'HH:mm:ss"),
				'end': format(end, "yyyy-MM-dd'T'HH:mm:ss"),
			};

			return this.http.post(environment.API_URL + 'tracers/ws/' + tracerId + '/GetTracerTraceWithStats/', json, {headers : headers});
		}
	}

	reActivitySIM() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/ActivateSIM/', {}, {headers : headers}).pipe(
				tap(async data => {
					var c = await this.alertController.create({
						cssClass: 'my-custom-class',
						header: this.translate.instant("GPS enabled"),
						subHeader: '',
						message: this.translate.instant("Your GPS has been reactivated, please restart your GPS now."),
						buttons: [{ role:"success", text: this.translate.instant('OK'), 
							handler: (d) => {
							} 
						}, ]
					});
					c.present();
				})
			);
		}
	}

	setActivityMode() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/SetActivityMode/', {}, {headers : headers}).pipe(
				tap(data => {
					this.setModeStartTime = new Date();
					this._tracerModeActivityProgressChange$.next();
				})
			);
		}
	}

	setLiveMode() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/SetLiveMode/', {}, {headers : headers}).pipe(
				tap(data => {
					this.setModeStartTime = new Date();
					this._tracerModeLiveProgressChange$.next();
				})
			);
		}
	}

	setLiveModeWithoutAlert() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/SetLiveModeWithoutAlert/', {}, {headers : headers}).pipe(
				tap(data => {
					this.setModeStartTime = new Date();
					this._tracerModeLiveProgressChange$.next();
				})
			);
		}
	}


	setSleepMode() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/SetSleepMode/', {}, {headers : headers}).pipe(
				tap(data => {
					this.setModeStartTime = new Date();
					this._tracerModeSleepProgressChange$.next();
				})
			);
		}
	}

	setStandByMode() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/SetStandByMode/', {}, {headers : headers}).pipe(
				tap(data => {
					this.setModeStartTime = new Date();
					this._tracerModeStandByProgressChange$.next();
				})
			);
		}
	}


	wakeUp() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/WakeUp/', {}, {headers : headers}).pipe(
				tap(data => {
      		this._tracerWakeUpStartChange$.next();
				})
			);
		}
	}

	reboot() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/Reboot/', {}, {headers : headers}).pipe(
				tap(data => {
				})
			);
		}
	}

	lightOnOff() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			var cmd = '/LightOnOff/'
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + cmd, {}, {headers : headers}).pipe(
				tap(data => {
				})
			);
		}
	}

	playSound() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			var cmd = '/FIND/'
			if(tracer.isModelSport() || tracer.isModelPT32())
				var cmd = '/FindOrStop/'
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + cmd, {}, {headers : headers}).pipe(
				tap(data => {
				})
			);
		}
	}

	playBip() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/Bip/', {}, {headers : headers}).pipe(
				tap(data => {
				})
			);
		}
	}


	playBipSound() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/BipSound/', {}, {headers : headers}).pipe(
				tap(data => {
				})
			);
		}
	}

	playBipVibrator() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/BipVibrator/', {}, {headers : headers}).pipe(
				tap(data => {
				})
			);
		}
	}

	test() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});

			//TESTAMR VIBRATOR     ??? RE TK2 pptk
			//VIBRATOR RESET(reboot) SHUTDOWN VERNO PROFILE(1,2-son,3-vibration,4)
			var json = 
			{
				'cmds': [
					{ 'cmd': 'TESTAMR_TK', 
					'arg1': null, 		
					'arg2': null,
					'arg3': null,
					'arg4': null }
				]
			};

			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/Test/', json, {headers : headers}).pipe(
				tap(data => {
				})
			);
		}
	}

	clearHistory() {
		var tracer: Tracer = this.getCurrentTracer();
		if(tracer != null) {
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});

			if(!this.historyIsActivate)
				this.historyInitDate();

			const start = new Date(this.historyDateStart.valueOf() + this.historyDateStart.getTimezoneOffset() * 60 * 1000);
			const end = new Date(this.historyDateEnd.valueOf() + this.historyDateEnd.getTimezoneOffset() * 60 * 1000);

			var json = 
			{
				'start': format(start, "yyyy-MM-dd'T'HH:mm:ss"),
				'end': format(end, "yyyy-MM-dd'T'HH:mm:ss"),
			};

			return this.http.post(environment.API_URL + 'tracers/ws/' + tracer.id + '/ClearHistory/', json, {headers : headers}).pipe(
				tap(data => {
				})
			);
		}
	}

  hasTracerSport() {
    for(var s in this.tracers) {
      var tracer = this.tracers[s];
      if(tracer.isModelSport())
      	return true;
    }
    return false;
  }

  hasTracerPT32() {
    for(var s in this.tracers) {
      var tracer = this.tracers[s];
      if(tracer.isModelPT32())
      	return true;
    }
    return false;
  }

  hasTracerPT21() {
    for(var s in this.tracers) {
      var tracer = this.tracers[s];
      if(tracer.isModelPT21())
      	return true;
    }
    return false;
  }



}
