import { Animal } from './animal';
import { parse, parseISO, differenceInSeconds, addDays, addHours, getDay, formatISO } from 'date-fns'
import { zonedTimeToUtc, utcToZonedTime, format, getTimezoneOffset } from 'date-fns-tz'
import { fr } from 'date-fns/locale';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {TracerService} from '../services/tracer.service'
import { Filesystem, Directory, Encoding, GetUriOptions } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';

const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export class TracerSubscription {
    id: number;
    tracerId: number;
    start: Date;
    durationDays: number;
    isActivated: boolean;

    end() {
        if(this.durationDays == -1
            || this.durationDays == -2)
            return null;

        var date: Date = addDays(this.start, this.durationDays);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        return date;
    }
}

export class Tracer {
    id: number;
    code: string;
    animalId: number;
    animal: Animal;
    isShare: boolean = false;

    iconAvatarRawUrl: string;
    iconAvatarRawUrlLocal: string;
    iconAvatarRawUrlLocalRaw: string;
    iconAvatarRawBase64: string;
    iconAvatarRawLoadedIs: boolean = false;

    iconAvatarUrl: string;
    iconAvatarUrlLocal: string;
    iconAvatarUrlLocalRaw: string;
    iconAvatarBase64: string;
    iconAvatarLoadedIs: boolean = false;

    positions = [];
    isHidden: boolean = false;

    iconMapUrl: string;
    iconMapUrlLocal: string;
    iconMapUrlLocalRaw: string;
    iconMapBase64: string;
    iconMapLoadedIs: boolean = false;
    iconMapErrorUrl: string;
    iconMapErrorUrlLocal: string;
    iconMapErrorUrlLocalRaw: string;
    iconMapErrorBase64: string;
    iconMapErrorLoadedIs: boolean = false;

    isSIMActivate: boolean = true;

    previousLatestPosition;
    latestPosition;
    latestPositionDate;
    latestPing;
    latestAltitude: number = 0;
    latestAccuracy: number = 99;

    isConnected: boolean = false;
    voltageLevel: number = 0;
    gsmStatus: number = 0;
    GPSTrackingIsOn: boolean = false;
    ChargeIsOn: boolean = false;
    ActivatedIs: boolean = false;
    networkType: string = '2G'
    GPSQualityIsBad: boolean = false;

    uploadIntervalSec: number;
    uploadPeriod: string;
    uploadIntervalLiveAlertIs: boolean = false;


    sleep1IsEnable: boolean;
    sleep1TimeStart: number;
    sleep1TimeEnd: number;
    sleep2IsEnable: boolean;
    sleep2TimeStart: number;
    sleep2TimeEnd: number;
    sleep3IsEnable: boolean;
    sleep3TimeStart: number;
    sleep3TimeEnd: number;

    subscriptionLatest: TracerSubscription = null;

    shareStart;
    shareEnd;

    confAlertVoltageNotif: boolean;
    confAlertVoltageEmail: boolean;
    confAlertFencingNotif: boolean;
    confAlertFencingEmail: boolean;
    confAlertCountryNotif: boolean;
    confAlertCountryEmail: boolean;

    model: string = null;
    defaultActivityType: string = 'walk';
    isSubscriptionUpdated: boolean = false;

    displayPopupComment: Boolean = false;

    findIsOn: Boolean = false;
    lightIsOn: Boolean = false;

    shareWithUsers = []

    isActivate() {
        return this.subscriptionLatest != null && (this.subscriptionLatest.end() == null || this.subscriptionLatest.end() >= new Date());
    }

    isModelPT21() {
        return this.model == 'PT21';
    }
    isModelSport() {
        return this.model == 'C1';
    }
    isModelPT32() {
        return this.model == 'PT32';
    }
    isModelMQTT() {
        return this.model == 'MQTT';
    }

    codeAnimal() {
        if(this.animal)
            return this.animal.name;
        return this.code;
    }

    getIconAvatarRawUrlLocal() {
        if(this.animal == null) {
            return this.iconAvatarRawUrlLocal;
        }
        else 
            return this.animal.iconAvatarRawUrlLocal;
    }

    getIconAvatarUrlLocal() {
        if(this.animal == null) {
            return this.iconAvatarUrlLocal;
        }
        else 
            return this.animal.iconAvatarUrlLocal;
    }

    getIconMapErrorUrlLocal() {
        if(this.animal == null)
            return this.iconMapErrorUrlLocal;
        else 
            return this.animal.iconMapErrorUrlLocal;
    }

    getIconMapUrlLocal() {
        if(this.animal == null)
            return this.iconMapUrlLocal;
        else 
            return this.animal.iconMapUrlLocal;
    }

    async setIconAvatarRaw(tracerService: TracerService, http: HttpClient, iconAvatarRawUrl: string) {
        if(iconAvatarRawUrl != this.iconAvatarRawUrl || this.iconAvatarRawBase64 == null || this.iconAvatarRawUrlLocal == null
            || (Capacitor.getPlatform() != 'web' && (this.iconAvatarRawUrlLocalRaw == null || Capacitor.convertFileSrc(await Filesystem.getUri({ path: this.iconAvatarRawUrlLocalRaw, directory: Directory.Data})['uri']) === undefined))) {
            
            var config = { responseType: 'blob' as 'blob' };
            http.get(iconAvatarRawUrl, config).subscribe(
                data => {
                    blobToBase64(data).then(async function(res) {
                        this.iconAvatarRawBase64 = res;
                        this.iconAvatarRawUrl = iconAvatarRawUrl;

                        if (Capacitor.getPlatform() === 'web') {
                            this.iconAvatarRawUrlLocal = this.iconAvatarRawrl;
                        } else {
                            var fileName: string = 'tracerIconAvatarRaw_' + this.id + '.svg';
                            while(Capacitor.convertFileSrc(await Filesystem.getUri({ path: fileName, directory: Directory.Data})['uri']) !== undefined) {
                                fileName = 'tracerIconAvatarRaw_' + this.id + '_' + Math.random() * 1000 + '.svg';
                            }

                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: this.iconAvatarRawBase64,
                                directory: Directory.Data
                            });
                            this.iconAvatarRawUrlLocalRaw = fileName;
                            this.iconAvatarRawUrlLocal = Capacitor.convertFileSrc(savedFile['uri']);
                        }
                        
                        this.iconAvatarRawLoadedIs = true;
                        tracerService._tracerAvatarChange$.next();
                    }.bind(this));
                },
                error => {
                    this.iconAvatarRawLoadedIs = true;
                    tracerService._tracerAvatarChange$.next();
                });
        } else {
            this.iconAvatarRawLoadedIs = true;
            tracerService._tracerAvatarChange$.next();
        }
    }

    async setIconAvatar(tracerService: TracerService, http: HttpClient, iconAvatarUrl: string) {
        if(iconAvatarUrl != this.iconAvatarUrl || this.iconAvatarBase64 == null || this.iconAvatarUrlLocal == null
            || (Capacitor.getPlatform() != 'web' && (this.iconAvatarUrlLocalRaw == null || Capacitor.convertFileSrc(await Filesystem.getUri({ path: this.iconAvatarUrlLocalRaw, directory: Directory.Data})['uri']) === undefined))) {
            
            var config = { responseType: 'blob' as 'blob' };
            http.get(iconAvatarUrl, config).subscribe(
                data => {
                    blobToBase64(data).then(async function(res) {
                        this.iconAvatarBase64 = res;
                        this.iconAvatarUrl = iconAvatarUrl;

                        if (Capacitor.getPlatform() === 'web') {
                            this.iconAvatarUrlLocal = this.iconAvatarUrl;
                        } else {
                            var fileName: string = 'tracerIconAvatar_' + this.id + '.svg';
                            while(Capacitor.convertFileSrc(await Filesystem.getUri({ path: fileName, directory: Directory.Data})['uri']) !== undefined) {
                                fileName = 'tracerIconAvatar_' + this.id + '_' + Math.random() * 1000 + '.svg';
                            }

                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: this.iconAvatarBase64,
                                directory: Directory.Data
                            });
                            this.iconAvatarUrlLocalRaw = fileName;
                            this.iconAvatarUrlLocal = Capacitor.convertFileSrc(savedFile['uri']);
                        }
                        
                        this.iconAvatarLoadedIs = true;
                        tracerService._tracerAvatarChange$.next();
                    }.bind(this));
                },
                error => {
                    this.iconAvatarLoadedIs = true;
                    tracerService._tracerAvatarChange$.next();
                });
        } else {
            this.iconAvatarLoadedIs = true;
            tracerService._tracerAvatarChange$.next();
        }
    }

    async setIconMap(tracerService: TracerService, http: HttpClient, iconUrl: string) {
        if(iconUrl != this.iconMapUrl || this.iconMapBase64 == null || this.iconMapUrlLocal == null
            || (Capacitor.getPlatform() != 'web' && (this.iconMapUrlLocalRaw == null || Capacitor.convertFileSrc(await Filesystem.getUri({ path: this.iconMapUrlLocalRaw, directory: Directory.Data})['uri']) === undefined))) {
            
            var config = { responseType: 'blob' as 'blob' };
            http.get(iconUrl, config).subscribe(
                data => {
                    blobToBase64(data).then(async function(res) {
                        this.iconMapBase64 = res;
                        this.iconMapUrl = iconUrl;

                        if (Capacitor.getPlatform() === 'web') {
                            this.iconMapUrlLocal = this.iconMapUrl;
                        } else {
                            var fileName: string = 'tracerIconMap_' + this.id + '.svg';
                            while(Capacitor.convertFileSrc(await Filesystem.getUri({ path: fileName, directory: Directory.Data})['uri']) !== undefined) {
                                fileName = 'tracerIconMap_' + this.id + '_' + Math.random() * 1000 + '.svg';
                            }

                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: this.iconMapBase64,
                                directory: Directory.Data
                            });
                            this.iconMapUrlLocalRaw = fileName;
                            this.iconMapUrlLocal = Capacitor.convertFileSrc(savedFile['uri']);
                        }

                        this.iconMapLoadedIs = true;
                        tracerService._tracerAvatarChange$.next();
                    }.bind(this));
                },
                error => {
                    this.iconMapLoadedIs = true;
                    tracerService._tracerAvatarChange$.next();
                });
        } else {
            this.iconMapLoadedIs = true;
            tracerService._tracerAvatarChange$.next();
        }
    }

    async setIconMapError(tracerService: TracerService, http: HttpClient, iconUrl: string) {
        if(iconUrl != this.iconMapErrorUrl || this.iconMapErrorBase64 == null || this.iconMapErrorUrlLocal == null
            || (Capacitor.getPlatform() != 'web' && (this.iconMapErrorUrlLocalRaw == null || Capacitor.convertFileSrc(await Filesystem.getUri({ path: this.iconMapErrorUrlLocalRaw, directory: Directory.Data})['uri']) === undefined))) {
            
            var config = { responseType: 'blob' as 'blob' };
            http.get(iconUrl, config).subscribe(
                data => {
                    blobToBase64(data).then(async function(res) {
                        this.iconMapErrorBase64 = res;
                        this.iconMapErrorUrl = iconUrl;

                        if (Capacitor.getPlatform() === 'web') {
                            this.iconMapErrorUrlLocal = this.iconMapErrorUrl;
                        } else {
                            var fileName: string = 'tracerIconMapError_' + this.id + '.svg';
                            while(Capacitor.convertFileSrc(await Filesystem.getUri({ path: fileName, directory: Directory.Data})['uri']) !== undefined) {
                                fileName = 'tracerIconMapError_' + this.id + '_' + Math.random() * 1000 + '.svg';
                            }
                            
                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: this.iconMapErrorBase64,
                                directory: Directory.Data
                            });
                            this.iconMapErrorUrlLocalRaw = fileName;
                            this.iconMapErrorUrlLocal = Capacitor.convertFileSrc(savedFile['uri']);
                        }

                        this.iconMapErrorLoadedIs = true;
                        tracerService._tracerAvatarChange$.next();
                    }.bind(this));
                },
                error => {
                    this.iconMapErrorLoadedIs = true;
                    tracerService._tracerAvatarChange$.next();
                });
        } else {
            this.iconMapErrorLoadedIs = true;
            tracerService._tracerAvatarChange$.next();
        }
    }

    getStatus() {
        var ret: string = "ok";
        if(differenceInSeconds(new Date(), this.latestPositionDate) > 60 * 15 ) {
            ret = "warning"
        }
        if(differenceInSeconds(new Date(), this.latestPing) > 60 * 15) {
            ret = "error";
        }
        return ret;
    }

    getStatusGPS() {
        var ret: boolean = true;
        if(differenceInSeconds(new Date(), this.latestPositionDate) > 60 * 15 ) {
            ret = false
        }
        return ret;
    }

    getStatusGPSIcon() {
        var ret: string = 'assets/icon/connection_ok.png';
        if(differenceInSeconds(new Date(), this.latestPositionDate) > 60 * 15 ) {
            ret = 'assets/icon/connection_error.png'
        }
        return ret;
    }

    getStatusGSM() {
        var ret: boolean = true;
        if(differenceInSeconds(new Date(), this.latestPing) > 60 * 15  && differenceInSeconds(new Date(), this.latestPositionDate) > 60 * 15) {
            ret = false
        }
        return ret;
    }

    getStatusGSMIcon() {
        var ret: string = 'assets/icon/connection_ok.png';
        if(differenceInSeconds(new Date(), this.latestPing) > 60 * 15  && differenceInSeconds(new Date(), this.latestPositionDate) > 60 * 15 ) {
            ret = 'assets/icon/connection_error.png'
        }
        return ret;
    }

    getStatusIcon() {
        var status: string = this.getStatus();
        if(status == "ok")
            return 'assets/icon/connection_ok.png';
        if(status == "warning")
            return 'assets/icon/connection_warning.png';
        if(status == "error")
            return 'assets/icon/connection_error.png';
        return 'assets/icon/connection_error.png';
    }

    getBatteryColorClass() {
        if(this.voltageLevel <= 0)
            return 'batteryStatus10';
        if(this.voltageLevel <= 10)
            return 'batteryStatus10';
        if(this.voltageLevel <= 20)
            return 'batteryStatus20';
        if(this.voltageLevel <= 40)
            return 'batteryStatus40';
        if(this.voltageLevel <= 60)
            return 'batteryStatus60';
        if(this.voltageLevel <= 90)
            return 'batteryStatus90';
        if(this.voltageLevel <= 100)
            return 'batteryStatus100';
        return 'batteryStatus10';
    }

    getBatteryIcon() {
        if(this.voltageLevel <= 0 || this.getStatus() == 'error')
            return 'assets/icon/battery_0.png';
        if(this.voltageLevel <= 10)
            return 'assets/icon/battery_10.png';
        if(this.voltageLevel <= 20)
            return 'assets/icon/battery_20.png';
        if(this.voltageLevel <= 30)
            return 'assets/icon/battery_30.png';
        if(this.voltageLevel <= 60)
            return 'assets/icon/battery_60.png';
        if(this.voltageLevel <= 90)
            return 'assets/icon/battery_90.png';
        if(this.voltageLevel <= 100)
            return 'assets/icon/battery_100.png';
        return 'assets/icon/battery_0.png';
    }

    getBatteryIconHorizontal() {
        if(this.voltageLevel <= 0 || this.getStatus() == 'error')
            return 'assets/icon/battery_horizontal_0.png';
        if(this.voltageLevel <= 10)
            return 'assets/icon/battery_horizontal_10.png';
        if(this.voltageLevel <= 20)
            return 'assets/icon/battery_horizontal_20.png';
        if(this.voltageLevel <= 30)
            return 'assets/icon/battery_horizontal_30.png';
        if(this.voltageLevel <= 60)
            return 'assets/icon/battery_horizontal_60.png';
        if(this.voltageLevel <= 90)
            return 'assets/icon/battery_horizontal_90.png';
        if(this.voltageLevel <= 100)
            return 'assets/icon/battery_horizontal_100.png';
        return 'assets/icon/battery_horizontal_0.png';
    }

}