import { Injectable } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { AlertService } from './../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from './../../environments/environment';
import { Tracer } from './../models/tracer';
import { Animal } from './../models/animal';
import { Subject, Observable } from 'rxjs';    
import { ModalController, NavController } from '@ionic/angular';
import { TracerService } from './../services/tracer.service';
import { tap } from 'rxjs/operators';
import { StorageService } from './storage.service'
import { DateUtilsService } from './../services/date-utils.service';
import { Filesystem, Directory, Encoding, GetUriOptions } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class AnimalService {
	public _animalListChange$ = new Subject<void>();
  public animalListChange$ = this._animalListChange$.asObservable();
	public _animalAvatarChange$ = new Subject<void>();
  public animalAvatarChange$ = this._animalAvatarChange$.asObservable();
	public _animalAvatarListChange$ = new Subject<void>();
  public animalAvatarListChange$ = this._animalAvatarListChange$.asObservable();
	public _animalCurrentChange$ = new Subject<void>();
  public animalCurrentChange$ = this._animalCurrentChange$.asObservable();

	animals = new Array<Animal>();
	animalsIsLoading = false;

	constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private http: HttpClient, 
    private alertService: AlertService,
    private navCtrl: NavController,
    private tracerService: TracerService,
    public storageService: StorageService,
		public dateUtils: DateUtilsService ) { 
    	this.storageService.get('animals').then(
			async data => {
				if(data != null) {
					if(this.animals.length > 0)
						return;
					
					this.animalsIsLoading = true;
					for(var d in data) {
						var animal: Animal = new Animal();
						animal.id = data[d].id;
						animal.name = data[d].name;
						animal.type = data[d].type;
						animal.iconAvatarRawUrl = data[d].iconAvatarRawUrl;
						animal.iconAvatarRawUrlLocal = data[d].iconAvatarRawUrlLocal ? data[d].iconAvatarRawUrlLocal: null;
						animal.iconAvatarRawUrlLocalRaw = data[d].iconAvatarRawUrlLocalRaw ? data[d].iconAvatarRawUrlLocalRaw: null;

						animal.iconAvatarUrl = data[d].iconAvatarUrl;
						animal.iconAvatarUrlLocal = data[d].iconAvatarUrlLocal ? data[d].iconAvatarUrlLocal: null;
						animal.iconAvatarUrlLocalRaw = data[d].iconAvatarUrlLocalRaw ? data[d].iconAvatarUrlLocalRaw: null;
						animal.iconMapUrlLocal = data[d].iconMapUrlLocal ? data[d].iconMapUrlLocal: null;
						animal.iconMapUrlLocalRaw = data[d].iconMapUrlLocalRaw ? data[d].iconMapUrlLocalRaw: null;
						animal.iconMapErrorUrlLocal = data[d].iconMapErrorUrlLocal ? data[d].iconMapErrorUrlLocal: null;
						animal.iconMapErrorUrlLocalRaw = data[d].iconMapErrorUrlLocalRaw ? data[d].iconMapErrorUrlLocalRaw: null;
						animal.iconAvatarBase64 = data[d].iconAvatarBase64;
						animal.iconAvatarLoadedIs = data[d].iconAvatarLoadedIs ? data[d].iconAvatarLoadedIs: true;
						animal.iconMapLoadedIs = data[d].iconMapLoadedIs ? data[d].iconMapLoadedIs: true;
						animal.iconMapErrorLoadedIs = data[d].iconMapErrorLoadedIs ? data[d].iconMapErrorLoadedIs: true;
						animal.GPX = data[d].GPX;
						animal.latestPosition = data[d].latestPosition;
						animal.latestPositionDate = data[d].latestPositionDate;
						animal.latestAltitude = data[d].latestAltitude;
						animal.latestPing = data[d].latestPing;
						animal.iconMapUrl = data[d].iconMapUrl;
						animal.iconMapBase64 = data[d].iconMapBase64;
						animal.iconMapErrorUrl = data[d].iconMapErrorUrl;
						animal.iconMapErrorBase64 = data[d].iconMapErrorBase64;
						animal.stravaCode = data[d].stravaCode ? data[d].stravaCode: null;
						animal.isShare = data[d].isShare ? data[d].isShare: null;

						this.animals.push(animal);
					}
					this.animalsIsLoading = false;
				}
				this.updateTracers();
				this._animalListChange$.next();
			},
			error => {
				this.animals = [];
			}
	    );

	    this.authService.loginStatus$.subscribe((isLoggedIn) => {
	      if(isLoggedIn){
	        this.GetAnimals().subscribe(() => {});
	      } else {
	      	this.animals = [];
	      }
	    })
	    this.animalAvatarListChange$.subscribe((isLoggedIn) => {
				this.SaveAnimals();
	    })
	    this.animalAvatarChange$.subscribe((isLoggedIn) => {
	    	if(this.animalsIsLoading) return;

	    	var allAvatarsUpdate: boolean = true;
				for(var animal in this.animals) {
					if(!this.animals[animal].iconAvatarRawLoadedIs || !this.animals[animal].iconAvatarLoadedIs || !this.animals[animal].iconMapLoadedIs || !this.animals[animal].iconMapErrorLoadedIs) {
						allAvatarsUpdate = false;
						break;
					}
				}

				if(allAvatarsUpdate) {
					this.updateTracers();
					this._animalAvatarListChange$.next();
					this._animalListChange$.next();
				}
	    })



		if(this.authService.isLoggedIn)
		{
	    	this.GetAnimals().subscribe(() => {});
	  }
	}


	GetAnimals() {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});
			return this.http.get(environment.API_URL + 'animals/ws/', {headers : headers})
			.pipe(
				tap(data => {
					var animalsSave = this.animals;
					this.animalsIsLoading = true;
					this.animals = [];
					for(var animal in data) {
						var animalo = new Animal();
						animalo.name = data[animal]['name'];
						animalo.id = data[animal]['id'];
						animalo.type = data[animal]['type'];
						animalo.stravaCode = data[animal]['stravaCode'];
						animalo.isShare = data[animal]['isShare'];

						for(var _animal in animalsSave) {
							if(animalsSave[_animal].id == animalo.id)
							{
								animalo.iconAvatarRawUrl =	animalsSave[_animal].iconAvatarRawUrl;
								animalo.iconAvatarRawUrlLocal =	animalsSave[_animal].iconAvatarRawUrlLocal;
								animalo.iconAvatarRawUrlLocalRaw =	animalsSave[_animal].iconAvatarRawUrlLocalRaw;
								animalo.iconAvatarRawBase64 =	animalsSave[_animal].iconAvatarRawBase64;

								animalo.iconAvatarUrl =	animalsSave[_animal].iconAvatarUrl;
								animalo.iconAvatarUrlLocal =	animalsSave[_animal].iconAvatarUrlLocal;
								animalo.iconAvatarUrlLocalRaw =	animalsSave[_animal].iconAvatarUrlLocalRaw;
								animalo.iconAvatarBase64 =	animalsSave[_animal].iconAvatarBase64;
								animalo.iconMapUrl =	animalsSave[_animal].iconMapUrl;
								animalo.iconMapUrlLocal =	animalsSave[_animal].iconMapUrlLocal;
								animalo.iconMapUrlLocalRaw =	animalsSave[_animal].iconMapUrlLocalRaw;
								animalo.iconMapBase64 =	animalsSave[_animal].iconMapBase64;
								animalo.iconMapErrorUrl =	animalsSave[_animal].iconMapErrorUrl;
								animalo.iconMapErrorUrlLocal =	animalsSave[_animal].iconMapErrorUrlLocal;
								animalo.iconMapErrorUrlLocalRaw =	animalsSave[_animal].iconMapErrorUrlLocalRaw;
								animalo.iconMapErrorBase64 =	animalsSave[_animal].iconMapErrorBase64;
							}
						}
						animalo.setIconAvatarRaw(this, this.http, environment.API_URL_MEDIA + data[animal]['iconAvatarRaw']);
						animalo.setIconAvatar(this, this.http, environment.API_URL_MEDIA + data[animal]['iconAvatar']);
						animalo.setIconMap(this, this.http, environment.API_URL_MEDIA + data[animal]['iconMap']);
						animalo.setIconMapError(this, this.http, environment.API_URL_MEDIA + data[animal]['iconMapError']);

						this.animals.push(animalo);
					}
					this.updateTracers();
					this.animalsIsLoading = false;

					this._animalListChange$.next();
					this._animalAvatarChange$.next();

					this.SaveAnimals();
				})
			);
		}
		return null;
	}


	SaveAnimals()
	{
		this.storageService.set('animals', this.animals);
	}

	getAnimalFromId(animalId) {
		if(animalId == null)
			return null;

		animalId = parseInt(animalId);
		for(var animal in this.animals) {
			if(this.animals[animal].id == animalId) {
				return this.animals[animal];
			}
		}
		return null;
	}


	updateTracers() {
		for(var a in this.animals) {
			var animal = this.animals[a];
			animal.tracer = null;
		}

		for(var t in this.tracerService.tracers) {
			var tracer = this.tracerService.tracers[t]; 
			if(tracer.animalId != null) {
				for(var a in this.animals) {
					var animal = this.animals[a];
					if(animal.id == tracer.animalId) {
						animal.tracer = tracer;
						this.tracerService.tracers[t].animal = animal;
					}
				}
			}
		}
		//this.tracerService._tracerListChange$.next();
	}

	StravaLink(animalId: number, stravaCode: string) {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});

			var json = 
			{
				'stravaCode': stravaCode,
			};

			this.http.post(environment.API_URL + 'animals/ws/' + animalId + '/StravaLink/', json, {headers : headers}).subscribe(data => {
			});
		}
		return null;
	}

	StravaUnLink(animalId: number) {
		if(this.authService.isLoggedIn)
		{
			var headers = new HttpHeaders({
				'Authorization': this.authService.getHttpHeaders()
			});

			var json = 
			{
			};

			this.http.post(environment.API_URL + 'animals/ws/' + animalId + '/StravaUnLink/', json, {headers : headers}).subscribe(data => {
			});
		}
		return null;
	}


}
