import { Injectable } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { AlertService } from './../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from './../../environments/environment';
import { Tracer } from './../models/tracer';
import { Animal } from './../models/animal';
import { Fencing } from './../models/fencing';
import { Activity } from './../models/activity';
import { Subject, Observable } from 'rxjs';    
import { ModalController, NavController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { StorageService } from './storage.service'
import { AnimalService } from './../services/animal.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private authService: AuthService,
    private translate: TranslateService,
    private http: HttpClient, 
    private alertService: AlertService,
    private navCtrl: NavController,
    private animalService: AnimalService,
    public storageService: StorageService) { }


  setDeviceId(deviceId: string, type: string) {
    if(this.authService.isLoggedIn)
    {
      var headers = new HttpHeaders({
        'Authorization': this.authService.getHttpHeaders(),
        'Content-Type': 'application/json'
      });


      var json = 
      {
        'deviceId': deviceId,
        'type': type
      };
      console.log(json);
      return this.http.post(environment.API_URL + 'accounts/ws/SetDeviceId/', json, {headers : headers}).pipe(
        tap(data => {
          console.log(data)
        })
      );
    }
  }
}
