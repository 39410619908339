export class Fencing {
    id: number;
    name: string;
    animals;
    type: string;
    circleLat: number;
    circleLon: number;
    circleRadius: number;
    boxP1Lat: number;
    boxP1Lon: number;
    boxP2Lat: number;
    boxP2Lon: number;
    boxP3Lat: number;
    boxP3Lon: number;
    boxP4Lat: number;
    boxP4Lon: number;
    polyPoints: string = '';
    beepCollarwhenFencingOut: boolean = false;

    getPolyPoints() {
        var ret = Array();
        var list = this.polyPoints.split(';');
        for(var l in list) {
            var c = list[l].split(',');
            if(c.length == 2)
                ret.push({'lon': Number(c[0]), 'lat': Number(c[1])});
        }
        return ret;
    }
}
