import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { User } from './../models/user';
import { StorageService } from './storage.service'
import { Subject, Observable } from 'rxjs';    
import * as bcrypt from 'bcryptjs';

import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password'
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  token:any;
  username:String;
  cart = []

  private _statusChange$ = new Subject<boolean>();
  public loginStatus$ = this._statusChange$.asObservable();
  redirectUrl: string = null;
  
  constructor(
    private http: HttpClient,
    public storageService: StorageService
  ) { }

  login(username: string, password: string) {
    return this.http.post(environment.API_URL + 'rest-auth/login/',
      {username: username, password: password}
    ).pipe(
      tap(token => {
        this.storageService.set('token', token);
        this.storageService.set('username', username);
        this.token = token;
        this.username = username;
        this.isLoggedIn = true;
        this._statusChange$.next(true);

        if (Capacitor.getPlatform() === 'ios') {
            SavePassword.promptDialog({
                username: username,
                password: password
            }).then(() => console.log('SavePassword success'))
            .catch((err) => console.error('SavePassword failure', err));
        }

        return token;
      }),
    );
  }

  register(fName: String, lName: String, email: String, password: String) {
    return this.http.post(environment.API_URL + 'auth/register',
      {fName: fName, lName: lName, email: email, password: password}
    )
  }

  logout() {
    const headers = new HttpHeaders({
      'Authorization': this.token["token_type"]+" "+this.token["access_token"]
    });
    return this.http.post(environment.API_URL + 'rest-auth/logout/', { headers: headers })
    .pipe(
      tap(data => {
        this.storageService.remove("token");
        this.storageService.remove("username");
        this.isLoggedIn = false;
        this._statusChange$.next(false);
        delete this.token;
        return data;
      })
    )
  }

  user() {
    const headers = new HttpHeaders({
      'Authorization': this.token["token_type"]+" "+this.token["access_token"]
    });
    return this.http.get<User>(environment.API_URL + 'auth/user/', { headers: headers })
    .pipe(
      tap(user => {
        return user;
      })
    )
  }
  getToken() {
    return this.storageService.get('token').then(
      data => {
        this.token = data;
        if(this.token != null) {
          this.storageService.get('username').then(
            data => { 
              this.username = data; 
              this.isLoggedIn=true;
              this._statusChange$.next(true);
            }
          );
        } else {
          this.isLoggedIn=false;
          this._statusChange$.next(false);
        }
      },
      error => {
        this.token = null;
        this.isLoggedIn=false;
        this._statusChange$.next(false);
      }
    );
  }

  getHttpHeaders() {
    if(this.token != null)
      return "Token " + this.token["key"];
    return null;
  }
}
