import {
 HttpEvent,
 HttpInterceptor,
 HttpHandler,
 HttpRequest,
 HttpResponse,
 HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { retry, catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EventsService } from './services/events.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(public events: EventsService) { }

 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //request = request.clone({headers: request.headers.set('Accept-Language', 'fr')});
 
   return next.handle(request)
     .pipe(
       retry(1),
       map(event => {
          if (event instanceof HttpResponse)
          {
            if (event.status >= 100 && event.status < 400)
              this.events.publish('NetworkSuccess');
            else
              this.events.publish('NetworkSuccess');
          }
          return event;
       }),
       catchError((error: HttpErrorResponse) => {
         let errorMessage = error.error;
         if (error.error instanceof ErrorEvent) {
           // client-side error
           //console.log('Error: ' + error.error.message);
           this.events.publish('NetworkError');
         } else {
           // server-side error
           //console.log('Error Code: ' + error.status + '\nMessage: ' + error.message);
           this.events.publish('NetworkError');
         }
         //window.alert(errorMessage);
         return throwError(errorMessage);
       })
      )
 }
}
