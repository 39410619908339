import { Injectable } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { AlertService } from './../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from './../../environments/environment';
import { Tracer } from './../models/tracer';
import { Animal } from './../models/animal';
import { Subject, Observable } from 'rxjs';    
import { ModalController, NavController } from '@ionic/angular';
import { TracerService } from './../services/tracer.service';
import { AnimalService } from './../services/animal.service';
import { DateUtilsService } from './../services/date-utils.service';
import { tap } from 'rxjs/operators';
import { StorageService } from './storage.service'
import { interval } from 'rxjs';
import { EventsService } from './../services/events.service';
import { ActivityService } from './../services/activity.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  	socket = null;
		private _websocketPositionNew$ = new Subject<any>();
  	public websocketPositionNew$ = this._websocketPositionNew$.asObservable();
		private _websocketStatusUpdate$ = new Subject<any>();
  	public websocketStatusUpdate$ = this._websocketStatusUpdate$.asObservable();

	  public _websocketBluetoothScanNotifyChange$ = new Subject<void>();
	  public websocketBluetoothScanNotifyChange$ = this._websocketBluetoothScanNotifyChange$.asObservable();
	  public _websocketBluetoothConDiscNotifyChange$ = new Subject<void>();
	  public websocketBluetoothConDiscNotifyChange$ = this._websocketBluetoothConDiscNotifyChange$.asObservable();

  	private timerReconnect = null;
  	private automaticTryToReconnect = true;

	constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private http: HttpClient, 
    private alertService: AlertService,
    private navCtrl: NavController,
    private tracerService: TracerService,
    private animalService: AnimalService,
    public storageService: StorageService,
    private router: Router,
		public dateUtils: DateUtilsService,
    private activityService: ActivityService,
		public events: EventsService) { 
	    this.tracerService.tracerListChange$.subscribe(() => {
	    	console.log('WebsocketService')
	      this.forceReOpenWebsocket();
	    });
	}

	forceReOpenWebsocket() {
	  	if(this.socket != null)
	  	{
	  		this.automaticTryToReconnect = false;
	  		this.socket.close();
	    	this.socket = null;
	  	}
	  	this.openWebsocket()
	}

	openWebsocket() {
    	if(this.timerReconnect != null)
    	{
    		this.timerReconnect.unsubscribe();
    		this.timerReconnect = null;
    	}

	  	if(this.socket != null)
	  		return;

	  	if(!this.authService.isLoggedIn && !document.URL.includes('share'))
	  		return;

	  	var tracersString = ''
	  	for(var _tracer in this.tracerService.tracers)
	  	{
	  		if (tracersString != '')
	  			tracersString = tracersString + ',' + this.tracerService.tracers[_tracer].id;
	  		else
	  			tracersString = String(this.tracerService.tracers[_tracer].id);
	  	}

	  	if(tracersString == '') return;
	  	this.socket = new WebSocket(environment.API_GPXConsumer+tracersString+'/');
	 
	    this.socket.onopen = () => {
	    	console.log('WebSockets connection created.');
        this.events.publish('NetworkSuccess');
	    };
	    this.socket.onerror = () => {
	    	//if(!this.automaticTryToReconnect)
        //	this.events.publish('NetworkError');
	    }

	    this.socket.onclose = () => {
	    	console.log('WebSockets connection closed.');
	    	this.socket = null;
	    	if(this.automaticTryToReconnect) {
		    	this.timerReconnect = interval(5000).subscribe(x => {
				    this.openWebsocket();
				});
		    }
		    this.automaticTryToReconnect = true;
	    };

	    this.socket.onmessage = (event) => {
	    	var data = JSON.parse(event.data);
	    	console.log(data)
	    	var tracer = this.tracerService.getTracerFromId(data["tracerId"]);
	    	var animal = this.animalService.getAnimalFromId(data["animalId"]);

	    	console.log(data)

	    	if('cmd' in data && data['cmd'] == 'BTScanR') {
	    		this._websocketBluetoothScanNotifyChange$.next(data);
	    	}
	    	if('cmd' in data && (data['cmd'] == 'BTCON' || data['cmd'] == 'BTDISC')) {
	    		this._websocketBluetoothConDiscNotifyChange$.next(data);
	    	}
	    	if('GPSPosition' in data)
	    	{
	    		if(tracer.latestPositionDate == null || this.dateUtils.fromString(data['GPSPosition']['date']) >= tracer.latestPositionDate) {
		    		tracer.previousLatestPosition = tracer.latestPosition;
						tracer.latestPosition = [data['GPSPosition']['lon'], data['GPSPosition']['lat']];
						tracer.latestPositionDate = this.dateUtils.fromString(data['GPSPosition']['date']);
						tracer.latestPing = this.dateUtils.fromString(data['GPSPosition']['date']);
						tracer.latestAltitude = data['GPSPosition']['altitude'];
		    	}
		    	this._websocketPositionNew$.next({tracer: tracer, animal: animal, data: data['GPSPosition']});
	    	}
	    	if('Status' in data)
	    	{
	    		if('GPSPosition' in data && (tracer.latestPositionDate == null || this.dateUtils.fromString(data['GPSPosition']['date']) >= tracer.latestPositionDate)) {
						tracer.latestPositionDate = this.dateUtils.fromString(data['Status']['latestPositionDate']);
						tracer.latestPing = this.dateUtils.fromString(data['Status']['latestPing']);
						tracer.latestAltitude = data['Status']['latestAltitude'];
					}

					if(!('GPSPosition' in data) || ('GPSPosition' in data && (tracer.latestPositionDate == null || this.dateUtils.fromString(data['GPSPosition']['date']) >= tracer.latestPositionDate))) {
						if('voltageLevel' in data['Status']) 
						{
							tracer.voltageLevel = data['Status']['voltageLevel'];
							tracer.gsmStatus = data['Status']['gsmStatus'];
							tracer.GPSTrackingIsOn = data['Status']['GPSTrackingIsOn'];
							tracer.ChargeIsOn = data['Status']['ChargeIsOn'];
							tracer.ActivatedIs = data['Status']['ActivatedIs'];
							tracer.uploadIntervalSec = data['Status']['uploadIntervalSec'];
							tracer.uploadPeriod = data['Status']['uploadPeriod'];
						}

						if('isConnected' in data['Status']) 
							tracer.isConnected = data['Status']['isConnected'];

						if('isSIMActivate' in data['Status']) 
							tracer.isSIMActivate = data['Status']['isSIMActivate'];
					}

					if('lightIsOn' in data['Status']) {
						tracer.lightIsOn = data['Status']['lightIsOn']
					}
					if('findIsOn' in data['Status']) {
						tracer.findIsOn = data['Status']['findIsOn']
					}
					if('GPSQualityIsBad' in data['Status']) {
						tracer.GPSQualityIsBad = data['Status']['GPSQualityIsBad']
					}

		    	this._websocketStatusUpdate$.next({tracer: tracer, animal: animal, data: data['Status']});
	    	}
	    	if('AlertIntervalLive' in data)
	    	{
	    		tracer.uploadIntervalLiveAlertIs = true;
	    	}

	    	if('activityStart' in data)
	    	{
		      this.tracerService.selectTracker(tracer.id);
          this.activityService.GetActivities().subscribe(() => {
            this.activityService.GetCurrentActivity().subscribe(() => {});
            this.tracerService.historyDeactivate();
            this.router.navigate(['map']);
          });
	    	}
	      
	      this.tracerService.SaveTracers();
	    };

	 
	    if (this.socket.readyState == WebSocket.OPEN) {
	      	this.socket.onopen(null);
	    }
	}
}
