import { Component, Input, Output, EventEmitter } from '@angular/core';
import type { OnInit } from '@angular/core';


@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnInit {
  @Input() items = [];
  @Input() selectedItem: string = '';
  @Input() title = 'Select Items';
  @Input() enableFilter: Boolean = true;

  @Output() selectionCancel = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<string>();
  @Output() searchChange = new EventEmitter<string>();
  
  workingSelectedValues: string = '';
  
  ngOnInit() {
    this.workingSelectedValues = this.selectedItem;
  }
  
  trackItems(index: number, item) {
    return item.id;
  }
  
  cancelChanges() {
    this.selectionCancel.emit();
  }
  
  confirmChanges() {
    this.selectionChange.emit(this.workingSelectedValues);
  }
  
  searchbarInput(ev) {
    this.searchChange.emit(ev.target.value);
  }
  
  radioGroupChange(event) {
    this.workingSelectedValues = event.target.value;
    this.confirmChanges();
  }

  setToNull() {
    this.workingSelectedValues = null;
    this.selectionChange.emit(this.workingSelectedValues);
  }

}
