import { HttpClient, HttpHeaders } from '@angular/common/http'
import {AnimalService} from '../services/animal.service'
import { Filesystem, Directory, Encoding, GetUriOptions } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';

const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};


export class Animal {
    id: number;
    name: string;
    type: string;
    isShare: boolean = false;

    iconAvatarRawUrl: string;
    iconAvatarRawUrlLocal: string;
    iconAvatarRawUrlLocalRaw: string;
    iconAvatarRawBase64: string;
    iconAvatarRawLoadedIs: boolean = false;

    iconAvatarUrl: string;
    iconAvatarUrlLocal: string;
    iconAvatarUrlLocalRaw: string;
    iconAvatarBase64: string;
    iconAvatarLoadedIs: boolean = false;

    GPX: string;
    latestPosition;
    latestPositionDate;
    latestAltitude: number = 0;
    latestPing;

    iconMapUrl: string;
    iconMapUrlLocal: string;
    iconMapUrlLocalRaw: string;
    iconMapBase64: string;
    iconMapLoadedIs: boolean = false;
    iconMapErrorUrl: string;
    iconMapErrorUrlLocal: string;
    iconMapErrorUrlLocalRaw: string;
    iconMapErrorBase64: string;
    iconMapErrorLoadedIs: boolean = false;
    
    tracer;

    stravaCode: string = null;

    async setIconAvatarRaw(animalService: AnimalService, http: HttpClient, iconAvatarRawUrl: string) {
        if(iconAvatarRawUrl != this.iconAvatarRawUrl || this.iconAvatarRawBase64 == null || this.iconAvatarRawUrlLocal == null 
            || (Capacitor.getPlatform() != 'web' && (this.iconAvatarRawUrlLocalRaw == null || Capacitor.convertFileSrc(await Filesystem.getUri({ path: this.iconAvatarRawUrlLocalRaw, directory: Directory.Data})['uri']) === undefined))) {

            var config = { responseType: 'blob' as 'blob' };
            http.get(iconAvatarRawUrl, config).subscribe(
                data => {
                    blobToBase64(data).then(async function(res) {
                        this.iconAvatarRawBase64 = res;
                        this.iconAvatarRawUrl = iconAvatarRawUrl;

                        if (Capacitor.getPlatform() === 'web') {
                            this.iconAvatarRawUrlLocal = this.iconAvatarRawUrl;
                        } else {
                            var fileName: string = 'animalIconAvatarRaw_' + this.id + '.svg';
                            while(Capacitor.convertFileSrc(await Filesystem.getUri({ path: fileName, directory: Directory.Data})['uri']) !== undefined) {
                                fileName = 'animalIconAvatarRaw_' + this.id + '_' + Math.random() * 1000 + '.svg';
                            }

                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: this.iconAvatarRawBase64,
                                directory: Directory.Data
                            });
                            this.iconAvatarRawUrlLocalRaw = fileName;
                            this.iconAvatarRawUrlLocal = Capacitor.convertFileSrc(savedFile['uri']);
                        }
                        
                        this.iconAvatarRawLoadedIs = true;
                        animalService._animalAvatarChange$.next();
                    }.bind(this));
                },
                error => {
                    this.iconAvatarRawLoadedIs = true;
                    animalService._animalAvatarChange$.next();
                });
        } else {
            this.iconAvatarRawLoadedIs = true;
            animalService._animalAvatarChange$.next();
        }
    }

    async setIconAvatar(animalService: AnimalService, http: HttpClient, iconAvatarUrl: string) {
        if(iconAvatarUrl != this.iconAvatarUrl || this.iconAvatarBase64 == null || this.iconAvatarUrlLocal == null 
            || (Capacitor.getPlatform() != 'web' && (this.iconAvatarUrlLocalRaw == null || Capacitor.convertFileSrc(await Filesystem.getUri({ path: this.iconAvatarUrlLocalRaw, directory: Directory.Data})['uri']) === undefined))) {

            var config = { responseType: 'blob' as 'blob' };
            http.get(iconAvatarUrl, config).subscribe(
                data => {
                    blobToBase64(data).then(async function(res) {
                        this.iconAvatarBase64 = res;
                        this.iconAvatarUrl = iconAvatarUrl;

                        if (Capacitor.getPlatform() === 'web') {
                            this.iconAvatarUrlLocal = this.iconAvatarUrl;
                        } else {
                            var fileName: string = 'animalIconAvatar_' + this.id + '.svg';
                            while(Capacitor.convertFileSrc(await Filesystem.getUri({ path: fileName, directory: Directory.Data})['uri']) !== undefined) {
                                fileName = 'animalIconAvatar_' + this.id + '_' + Math.random() * 1000 + '.svg';
                            }

                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: this.iconAvatarBase64,
                                directory: Directory.Data
                            });
                            this.iconAvatarUrlLocalRaw = fileName;
                            this.iconAvatarUrlLocal = Capacitor.convertFileSrc(savedFile['uri']);
                        }
                        
                        this.iconAvatarLoadedIs = true;
                        animalService._animalAvatarChange$.next();
                    }.bind(this));
                },
                error => {
                    this.iconAvatarLoadedIs = true;
                    animalService._animalAvatarChange$.next();
                });
        } else {
            this.iconAvatarLoadedIs = true;
            animalService._animalAvatarChange$.next();
        }
    }

    async setIconMap(animalService: AnimalService, http: HttpClient, iconUrl: string) {
        if(iconUrl != this.iconMapUrl || this.iconMapBase64 == null || this.iconMapUrlLocal == null
            || (Capacitor.getPlatform() != 'web' && (this.iconMapUrlLocalRaw == null || Capacitor.convertFileSrc(await Filesystem.getUri({ path: this.iconMapUrlLocalRaw, directory: Directory.Data})['uri']) === undefined))) {
            
            var config = { responseType: 'blob' as 'blob' };
            http.get(iconUrl, config).subscribe(
                data => {
                    blobToBase64(data).then(async function(res) {
                        this.iconMapBase64 = res;
                        this.iconMapUrl = iconUrl;

                        if (Capacitor.getPlatform() === 'web') {
                            this.iconMapUrlLocal = this.iconMapUrl;
                        } else {
                            var fileName: string = 'animalIconMap_' + this.id + '.svg';
                            while(Capacitor.convertFileSrc(await Filesystem.getUri({ path: fileName, directory: Directory.Data})['uri']) !== undefined) {
                                fileName = 'animalIconMap_' + this.id + '_' + Math.random() * 1000 + '.svg';
                            }

                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: this.iconMapBase64,
                                directory: Directory.Data
                            });
                            this.iconMapUrlLocalRaw = fileName;
                            this.iconMapUrlLocal = Capacitor.convertFileSrc(savedFile['uri']);
                        }

                        this.iconMapLoadedIs = true;
                        animalService._animalAvatarChange$.next();
                    }.bind(this));
                },
                error => {
                    this.iconMapLoadedIs = true;
                    animalService._animalAvatarChange$.next();
                });
        } else {
            this.iconMapLoadedIs = true;
            animalService._animalAvatarChange$.next();
        }
    }

    async setIconMapError(animalService: AnimalService, http: HttpClient, iconUrl: string) {
        if(iconUrl != this.iconMapErrorUrl || this.iconMapErrorBase64 == null || this.iconMapErrorUrlLocal == null
            || (Capacitor.getPlatform() != 'web' && (this.iconMapErrorUrlLocalRaw == null || Capacitor.convertFileSrc(await Filesystem.getUri({ path: this.iconMapErrorUrlLocalRaw, directory: Directory.Data})['uri']) === undefined))) {
            
            var config = { responseType: 'blob' as 'blob' };
            http.get(iconUrl, config).subscribe(
                data => {
                    blobToBase64(data).then(async function(res) {
                        this.iconMapErrorBase64 = res;
                        this.iconMapErrorUrl = iconUrl;


                        if (Capacitor.getPlatform() === 'web') {
                            this.iconMapErrorUrlLocal = this.iconMapErrorUrl;
                        } else {
                            var fileName: string = 'animalIconMapError_' + this.id + '.svg';
                            while(Capacitor.convertFileSrc(await Filesystem.getUri({ path: fileName, directory: Directory.Data})['uri']) !== undefined) {
                                fileName = 'animalIconMapError_' + this.id + '_' + Math.random() * 1000 + '.svg';
                            }
                            
                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: this.iconMapErrorBase64,
                                directory: Directory.Data
                            });
                            this.iconMapErrorUrlLocalRaw = fileName;
                            this.iconMapErrorUrlLocal = Capacitor.convertFileSrc(savedFile['uri']);
                        }

                        this.iconMapErrorLoadedIs = true;
                        animalService._animalAvatarChange$.next();
                    }.bind(this));
                },
                error => {
                    this.iconMapErrorLoadedIs = true;
                    animalService._animalAvatarChange$.next();
                });
        } else {
            this.iconMapErrorLoadedIs = true;
            animalService._animalAvatarChange$.next();
        }
    }
}