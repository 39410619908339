//@ts-nocheck
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { TracerService } from './../services/tracer.service';
import { AnimalService } from './../services/animal.service';
import { WebsocketService } from './../services/websocket.service';
import { DateUtilsService } from './../services/date-utils.service';
import { MenuController, AlertController } from '@ionic/angular';
import { FencingService } from './../services/fencing.service';
import { ActivityService } from './../services/activity.service';
import { EventsService } from './../services/events.service';
import { ToastController } from '@ionic/angular';
import { BluetoothService } from './../services/bluetooth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from './../../environments/environment';
import { parse, parseISO, differenceInSeconds, addDays, addHours, getDay, fromUnixTime, addYears } from 'date-fns'

import {  Chart, ChartData, ChartOptions, ChartType } from 'chart.js';
import 'chartjs-adapter-date-fns';

import { parse, parseISO, differenceInSeconds, addDays, addHours, getDay } from 'date-fns'
import { fr } from 'date-fns/locale';
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz'

import { Tracer } from '../models/tracer';
import { Animal } from '../models/animal';

import { Clipboard } from '@capacitor/clipboard';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Platform, NavController } from '@ionic/angular';

Chart.defaults.defaultFontStyle="Bold";

var globalStatusAvatarUrl = null;

@Component({
  selector: 'app-status',
  templateUrl: './status.page.html',
  styleUrls: ['./status.page.scss'],
})
export class StatusPage implements OnInit {

  segment = '0';

  tracer: Tracer = null;
  displayMessage: number = 0;
  voltageLevel: String = '??';

  statusGPSIcon: string = null;
  statusGSMIcon: string = null;
  statusNetworkType: string = null;

  statusBatteryIcon: string = null;
  statusBatteryLevel: string = null;
  statusGPSIonChip: string = null;
  statusGSMIonChip: string = null;
  statusBatteryIonChip: string = null;

  latestVoltageLevel: String = '';
  latestPingDiff: SafeHtml = '';
  latestPingDate: String = '';

  subscriptionEndDate: String = '';
  subscriptionLabel: String = '';

  latestKnownPosition;
  latestKnownPositionDateDiff: SafeHtml = '';
  latestKnownPositiondate : String = '';

  btStandByModeIsVisible: boolean = false;
  btSleepModeIsVisible: boolean = false;
  btLiveModeIsVisible: boolean = false;
  btInterogationIsVisible: boolean = true;

  statusIsActivate: boolean = true;



  loadingData = false

  dayDate: Date;
  dayDateIonic;
  dayDateString: string;
  dayDateMax: string;

  weekDate: Date;
  weekDateIonic;
  weekDateString: string;

  monthDate: Date;
  monthDateIonic;
  monthDateString: string;


  //Chart
  chartData: ChartData<'line'> = {
    labels: ['Inactif', 'Faible activité', 'Forte activité'],
    datasets: [
      { 
        data: [], 
        label: '',
        backgroundColor: [],
      },
    ],
  };
  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: this._translate.instant("Intensity of activity")
      },
      tooltip: {
        enabled: false,
      }
    },
    animation: {
      duration: 1500,
      onProgress: function(chart) {
        const ctx = this.ctx;
        ctx.textAlign = 'center';
        ctx.font = '600 16px "Microsoft Yahei"'
        ctx.textBaseline = 'bottom';

        let dataSum = 0;
        if(this._sortedMetasets.length > 0 && this._sortedMetasets[0].data.length > 0) {
          const dataset = this._sortedMetasets[0].data[0].$context.dataset;
          dataSum = dataset.data.reduce((p, c) => p + c, 0);
        }
        if(dataSum <= 0) return;

        var radius= 0;
        var x = 0;
        var y = 0;
        var startAngle = null;
        var endAngle = 0;
        this._sortedMetasets.forEach(meta => {
          meta.data.forEach(metaData => {
            const dataset = metaData.$context.dataset;
            const datasetIndex = metaData.$context.dataIndex;
            const value = dataset.data[datasetIndex];
            const percent = (Math.round(value / dataSum * 1000) / 10) + '%';
            const mid_radius = metaData.innerRadius + (metaData.outerRadius - metaData.innerRadius) * 0.6;
            const start_angle = metaData.startAngle;
            const end_angle = metaData.endAngle;
            if(start_angle === end_angle) return; // hidden
            const mid_angle = start_angle + (end_angle - start_angle) / 2;

            const _x = mid_radius * Math.cos(mid_angle);
            const _y = mid_radius * Math.sin(mid_angle);

            ctx.fillStyle = '#fff';
            ctx.fillText(percent, metaData.x + _x, metaData.y + _y);

            radius = metaData.innerRadius;
            x = metaData.x;
            y = metaData.y;
            endAngle = end_angle;
            if(startAngle == null)
              startAngle = start_angle;
          });
        });

        ctx.save();
        var image = new Image();      
        image.src = globalStatusAvatarUrl;
        var imageSize = radius;
        ctx.beginPath()
        ctx.arc(x, y, imageSize, startAngle, endAngle, false);
        ctx.clip();
        ctx.drawImage(image, x - imageSize, y - imageSize, imageSize * 2, imageSize * 2);
        ctx.stroke()
        ctx.restore();
      },
    }
  };

  charLineType: ChartType = 'line'
  chartLineData: ChartData<'line'> = {
    labels: [],
    datasets: [],
  };
  chartLineOptions: ChartOptions = {
    responsive: true,
    plugins: {
      title: { display: false },
      legend: { display: false },
      corsair: {
        color: 'black',
      },
      tooltip: {}
    },
    scales: {}
  };


  chartLineOptionsTooltipDay() {
    return {
        mode: 'index',
        intersect: false,
        callbacks: {
            label: function(context) {
              return '';
            }
        }
      };
  }

  chartLineOptionsTooltipWeekMonth() {
    return {
        mode: 'index',
        intersect: false,
        callbacks: {
            label: function(context) {
              return context.formattedValue + '%';
            }
        }
      };
  }

  chartLineOptionsScaleDay(max) {
    return {
      y: {
          display: false,
          min: -50,
          max: max,
          ticks: {
              font: { size: 10 }
          }
      },
      x: {
          display: true,
          grid: {
            display: false
          },
          ticks: {
              font: { size: 10 }
          },
          type: 'time',
          time: {
              unit: 'minute',
              tooltipFormat: 'HH:mm:ss',
              displayFormats: {minute: 'HH:mm'},
          },
          adapters: { 
            date: {
              locale: null, 
            },
          }, 
      }
    }
  }

  chartLineOptionsScaleWeekMonth() {
    return {
      y: {
          stacked: true,
          display: false,
          min: 0,
          ticks: {
              font: { size: 10 }
          }
      },
      x: {
          stacked: true,
          display: true,
          grid: {
            display: false
          },
          ticks: {
              font: { size: 10 }
          },
      }
    }
  }

  chartLineGetDataSetDay() {
    return { 
      data: [], 
      label: '',
      backgroundColor: 'rgba(255, 99, 132, 0)',
      segment: {
        backgroundColor: function(context){
          const yval = context.p0.raw;
          var color= '';
          if (yval == -50) {
            color = 'rgb(193.7, 187.2, 188.7)'
          } else if(yval == -25) {
            color = 'rgb(135.7, 204.8, 255.0)'
          } else if(yval <= 200) {
            color = 'rgb(255.0, 230.4, 165.7)'
          } else {
            color = 'rgb(255.0, 174.8, 192.8)'
          }
          return color;
        },
      },
      pointBackgroundColor: '#88cdff',
      pointBorderColor: '#88cdff',
      borderWidth: 0,
      fill: true,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 0,
      stepped: true,
    }
  }

  chartLineGetDataSetWeekMonth(color) {
    return { 
      data: [], 
      label: '',
      backgroundColor: color,
      pointBackgroundColor: '#88cdff',
      pointBorderColor: '#88cdff',
      borderWidth: 0,
      fill: true,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 0,
      stepped: true,
    }
  }

  constructor(private modalController: ModalController,
    private authService: AuthService,
    private http: HttpClient, 
    private _translate: TranslateService, 
    private tracerService: TracerService,
    private bluetoothSerice: BluetoothService,
    private animalService: AnimalService,
    private websocketService: WebsocketService,
    private fencingService: FencingService,
    public dateUtils: DateUtilsService,
    private activityService: ActivityService,
    public sanitizer: DomSanitizer,
    private navCtrl: NavController,
    public alertController: AlertController,
    public toastController: ToastController) { }

  ngOnInit() {
    this.tracer = this.tracerService.getCurrentTracer();
    this.updateracerStatus();

    this.tracerService.tracerCurrentChange$.subscribe(async () => {
      this.tracer = this.tracerService.getCurrentTracer();
      if(await this.modalController.getTop()) {
        console.log('tracerCurrentChange$')
        this.updateracerStatus();
      }
    });

    this.animalService.animalAvatarListChange$.subscribe(() => {
      this._updateracerStatus();
    })
  }

  updateracerStatus() {
    var d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setDate(d.getDate());
    this.dayDateMax = format(d, "yyyy-MM-dd'T'HH:mm:ss");

    this.dayDate = new Date(d);
    this.dayDateIonic = this.dateUtils.toStringIso(this.dayDate);

    d.setDate(d.getDate() - d.getDay() + 1);
    this.weekDate = d;
    this.weekDateIonic = this.dateUtils.toStringIso(this.weekDate);

    d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d = addDays(d, -d.getDate() + 1)
    this.monthDate = d;
    this.monthDateIonic = this.dateUtils.toStringIso(this.monthDate);

    this._updateracerStatus();
  }

  _updateracerStatus() {
    if(this.tracer == null) return;

    this.dayDateNextIsDisplay()
    this.dayDateBackIsDisplay()
    this.weekDateNextIsDisplay()
    this.weekDateBackIsDisplay()
    this.monthDateNextIsDisplay()
    this.monthDateBackIsDisplay()

    var start = null;
    var end = null;

    if (this.segment == '-1') {
      return ;
    } else if (this.segment == '0') {
      //Stats du jour
      start = new Date(this.dayDate)
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      end = new Date(this.dayDate)
      if(end.getDate() != (new Date()).getDate()) {
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
      } else {
        end = new Date()
      }
    } else if (this.segment == '1') {
      //Stats semaine
      this.charLineType = 'bar'
      this.chartLineOptions['scales'] = this.chartLineOptionsScaleWeekMonth()
      this.chartLineOptions['plugins']['tooltip'] = this.chartLineOptionsTooltipWeekMonth()

      start = new Date(this.weekDate)
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      end = new Date(this.weekDate)
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end = addDays(end, 6)
      if(end > new Date())
        end = new Date()
    } else if (this.segment == '2') {
      //Stats du mois
      this.charLineType = 'bar'
      this.chartLineOptions['scales'] = this.chartLineOptionsScaleWeekMonth()
      this.chartLineOptions['plugins']['tooltip'] = this.chartLineOptionsTooltipWeekMonth()

      start = new Date(this.monthDate)
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      end = new Date(this.monthDate)
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end.setMonth(end.getMonth() + 1)
      end = addDays(end, -1)
      if(end > new Date())
        end = new Date()
    }

    var formData = {
      'start': this.dateUtils.toStringIso(start),
      'end': this.dateUtils.toStringIso(end),
    }
    var headers = new HttpHeaders({
      'Authorization': this.authService.getHttpHeaders(),
      'content-type': 'application/json'
    });

    globalStatusAvatarUrl = this.tracerService.getCurrentTracer().iconAvatarRawBase64;
    this.chartData['datasets'][0]['data'] = []
    this.chartData['labels'] = []
    this.chartData['datasets'][0]['backgroundColor'] = []

    this.chartLineData['datasets'] = [this.chartLineGetDataSetDay(),]
    this.chartLineData['labels'] = []

    if(this.tracer.animal) {
      this.loadingData = true;
      this.http.post(environment.API_URL + 'animals/ws/' + this.tracer.animal.id + '/getStepStats/', JSON.stringify(formData), {headers : headers}).subscribe((response: any[]) => {
        this.loadingData = false;

        this.chartData['datasets'][0]['data'] = []
        this.chartData['labels'] = []
        this.chartData['datasets'][0]['backgroundColor'] = []
        this.chartLineData['datasets'] = [this.chartLineGetDataSetDay()]
        this.chartLineData['labels'] = []
    
        var nbNoDataMin = 0;
        var nbInactifMin = 0;
        var nbFaibleActiviteMin = 0;
        var nbForteActiviteMin = 0;
        var totalMin = 0;

        //valeurs de chaque catégorie par jour pour les stats semaines et mois
        var daysValues = []

        response['oldSteps'] = []
        for(var item in response['steps']) {
          item = response['steps'][item];
          var newItem = {}
          Object.assign(newItem, item);
          response['oldSteps'].push(newItem)
        }

        //Si on a une période sur 2 jours, on la split
        response['newSteps'] = []
        for(var item in response['steps']) {
          item = response['steps'][item];
          item['start'] = this.dateUtils.fromString(item['start']);
          item['end'] = this.dateUtils.fromString(item['end']);

          if(item['start'].getDay() == item['end'].getDay()) {
            response['newSteps'].push(item)
          } else {
            var newItem = {}
            Object.assign(newItem, item);
            newItem['end'] = addDays(newItem['end'], -1)
            newItem['end'].setHours(23)
            newItem['end'].setMinutes(59)
            newItem['end'].setSeconds(59)
            if(newItem['end'] >= start)
              response['newSteps'].push(newItem)

            newItem = {}
            Object.assign(newItem, item);
            newItem['start'] = addDays(newItem['start'], 1)
            newItem['start'].setHours(0)
            newItem['start'].setMinutes(0)
            newItem['start'].setSeconds(0)
            if(newItem['start'] <= end)
              response['newSteps'].push(newItem)
          }
        }
        response['steps'] = response['newSteps']

        var max = 10;
        for(var item in response['steps']) {
          item = response['steps'][item];
          var _start = item['start'];
          var _end = item['end'];

          if(item == response['steps'][0] && _start > start )
            _start = start
          if(_start < start)
            _start = start
          if(_end > end)
            _end = end

          var diffMs = (_end.valueOf() - _start.valueOf());
          var diffMins = Math.round(diffMs / 1000);

          totalMin += diffMins;
          if(item['step'] == -1) {
            nbNoDataMin += diffMins;
          }
          else if(item['step'] == 0) {
            nbInactifMin += diffMins;
          }
          else if(item['step'] <= 200) {
            nbFaibleActiviteMin += diffMins;
          }
          else{
            nbForteActiviteMin += diffMins;
          }

          max = item['step'] > max ? item['step']: max;

          if(this.segment == '0') {
            //Stats jour
            this.chartLineData['labels'].push(_start >= start ? _start: start)
            this.chartLineData['datasets'][0]['data'].push(item['step'] == - 1 ? -50: item['step'] == 0 ? -25: item['step'])
            this.chartLineData['labels'].push(_end <= end ? _end: end)
            this.chartLineData['datasets'][0]['data'].push(item['step'] == - 1 ? -50: item['step'] == 0 ? -25: item['step'])
          } else {
            //Stats semaine/mois
            var date = new Date(_start >= start ? _start: start)
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);

            var found = false
            var dayValue = null;
            for(dayValue in daysValues) {
              dayValue = daysValues[dayValue]
              if(dayValue['date'].getDate() == date.getDate()) {
                found = true;
              }
            }

            if(!found) {
              dayValue = {'date': date, 'totalMin': 0, 'nbNoDataMin': 0, 'nbInactifMin': 0, 'nbFaibleActiviteMin': 0, 'nbForteActiviteMin': 0}
              daysValues.push(dayValue)
            }

            dayValue['totalMin'] += diffMins;
            if(item['step'] == -1) {
              dayValue['nbNoDataMin'] += diffMins;
            } else if(item['step'] == 0) {
              dayValue['nbInactifMin'] += diffMins;
            } else if(item['step'] <= 200) {
              dayValue['nbFaibleActiviteMin'] += diffMins;
            } else {
              dayValue['nbForteActiviteMin'] += diffMins;
            }
          }
        }

        this.chartData['labels'].push(this._translate.instant("No data"))
        this.chartData['labels'].push(this._translate.instant("Inactive"))
        this.chartData['labels'].push(this._translate.instant("Low"))
        this.chartData['labels'].push(this._translate.instant("Strong"))

        this.chartData['datasets'][0]['backgroundColor'] = ['rgb(193.7, 187.2, 188.7)', 'rgb(135.7, 204.8, 255.0)', 'rgb(255.0, 230.4, 165.7)', 'rgb(255.0, 174.8, 192.8)']

        this.chartData['datasets'][0]['data'].push(Math.round(nbNoDataMin / totalMin * 100))
        this.chartData['datasets'][0]['data'].push(Math.round(nbInactifMin / totalMin * 100))
        this.chartData['datasets'][0]['data'].push(Math.round(nbFaibleActiviteMin / totalMin * 100))
        this.chartData['datasets'][0]['data'].push(Math.round(nbForteActiviteMin / totalMin * 100))

        if(this.segment == '0' && response['steps'].length > 0) {
          //Stats du jour
          this.charLineType = 'line'
          this.chartLineOptions['scales'] = this.chartLineOptionsScaleDay(max)
          this.chartLineOptions['plugins']['tooltips'] = this.chartLineOptionsTooltipDay()

          var item = response['steps'][response['steps'].length - 1]
          var _end = item['end']
          this.chartLineData['labels'].push(_end <= end ? _end: end)
          this.chartLineData['datasets'][0]['data'].push(item['step'] == - 1 ? -50: item['step'] == 0 ? -25: item['step'])
          this.chartLineData['labels'].push(end)
          this.chartLineData['datasets'][0]['data'].push(item['step'] == - 1 ? -50: item['step'] == 0 ? -25: item['step'])
        }
        else if(this.segment == '1' || this.segment == '2') {

          this.chartLineData['datasets'] = []
          this.chartLineData['labels'] = []

          var dataSetNbNoDataMin = this.chartLineGetDataSetWeekMonth('rgb(193.7, 187.2, 188.7)');
          var dataSetNbInactifMin = this.chartLineGetDataSetWeekMonth('rgb(135.7, 204.8, 255.0)');
          var dataSetNbFaibleActiviteMin = this.chartLineGetDataSetWeekMonth('rgb(255.0, 230.4, 165.7)');
          var dataSetNbForteActiviteMin = this.chartLineGetDataSetWeekMonth('rgb(255.0, 174.8, 192.8)');

          for(var dayValue in daysValues) {
            dayValue = daysValues[dayValue]
            this.chartLineData['labels'].push(this.dateUtils.toStringDay(dayValue['date']))

            dataSetNbNoDataMin['data'].push(Math.round(dayValue['nbNoDataMin'] / dayValue['totalMin'] * 100))
            dataSetNbInactifMin['data'].push(Math.round(dayValue['nbInactifMin'] / dayValue['totalMin'] * 100))
            dataSetNbFaibleActiviteMin['data'].push(Math.round(dayValue['nbFaibleActiviteMin'] / dayValue['totalMin'] * 100))
            dataSetNbForteActiviteMin['data'].push(Math.round(dayValue['nbForteActiviteMin'] / dayValue['totalMin'] * 100))
          }
          
          this.chartLineData['datasets'].push(dataSetNbNoDataMin)
          this.chartLineData['datasets'].push(dataSetNbInactifMin)
          this.chartLineData['datasets'].push(dataSetNbFaibleActiviteMin)
          this.chartLineData['datasets'].push(dataSetNbForteActiviteMin)
        }
      })
    }

    //Statut du tracer
    this.statusGPSIcon = this.tracer.getStatusGPSIcon();
    this.statusGSMIcon = this.tracer.getStatusGSMIcon();
    this.statusNetworkType = this.tracer.networkType;;
    this.statusBatteryIcon = this.tracer.getBatteryIconHorizontal();
    this.statusBatteryLevel = this.tracer.getStatus() != 'error' ? String(this.tracer.voltageLevel) : '??';
    this.statusGPSIonChip = this.tracer.getStatusGPS() ? 'success': 'danger';
    this.statusGSMIonChip = this.tracer.getStatusGSM() ? 'success': 'danger';
    this.statusBatteryIonChip = this.tracer.voltageLevel > 32 && this.tracer.getStatus() != 'error' ? 'success': 'danger';
    this.statusIsActivate = this.tracer.isActivate();

    var messageString = '';
    if(this.tracer.getStatus() == 'error' || !this.tracer.isConnected)
    {
      this.voltageLevel = '??';
      this.displayMessage = 0;
    }
    else if(this.tracer.getStatus() == 'warning' && this.tracer.uploadPeriod == 'SLEEP')
    {
      this.voltageLevel = String(this.tracer.voltageLevel);
      this.displayMessage = 1;
    }
    else if(this.tracer.getStatus() == 'warning')
    {
      this.voltageLevel = String(this.tracer.voltageLevel);
      this.displayMessage = 2;
    }
    else if(this.tracer.getStatus() == 'ok')
    {
      this.voltageLevel = String(this.tracer.voltageLevel);
      this.displayMessage = 3;
    }

    this.latestVoltageLevel = String(this.tracer.voltageLevel);
    this.latestPingDiff = this.sanitizer.bypassSecurityTrustHtml(this.dateUtils.toStringDiff(this.tracer.latestPing));
    this.latestPingDate = this.dateUtils.toString(this.tracer.latestPing);

    this.latestKnownPosition = this.tracer.latestPosition;
    this.latestKnownPositionDateDiff = this.dateUtils.toStringDiff(this.tracer.latestPositionDate);
    this.latestKnownPositiondate = this.dateUtils.toString(this.tracer.latestPositionDate);

    if(this.tracer.subscriptionLatest)
    {
      if(this.tracer.subscriptionLatest.durationDays == -1)
      {
        this.subscriptionEndDate = null;
        this.subscriptionLabel = this._translate.instant("Unlimited");
      }
      else if(this.tracer.subscriptionLatest.durationDays == -2)
      {
        this.subscriptionEndDate = null;
        this.subscriptionLabel = this._translate.instant("Offer without subscription");
      }
      else
      {
        this.subscriptionEndDate = this.dateUtils.toStringWithoutTime(this.tracer.subscriptionLatest.end());
        var nbYears: number = this.tracer.subscriptionLatest.durationDays / 370;
        this.subscriptionLabel = String(nbYears) + " ";
        if(nbYears > 1) 
          this.subscriptionLabel += this._translate.instant("years");
        else
          this.subscriptionLabel += this._translate.instant("year");
      }
    }

    if(this.tracer.getStatus() == 'error')
    {
      this.btStandByModeIsVisible = false;
      this.btSleepModeIsVisible = false;
      this.btLiveModeIsVisible = false;
      this.btInterogationIsVisible = true;
    }
    else if(this.tracer.uploadPeriod == 'LIVE')
    {
      this.btStandByModeIsVisible = false;
      this.btSleepModeIsVisible = false;
      this.btLiveModeIsVisible = true;
      this.btInterogationIsVisible = false;
    } else if(this.tracer.uploadPeriod == 'STANDBY')
    {
      this.btStandByModeIsVisible = true;
      this.btLiveModeIsVisible = false;
      this.btSleepModeIsVisible = false;
      this.btInterogationIsVisible = false;
    } else 
    {
      this.btStandByModeIsVisible = false;
      this.btLiveModeIsVisible = false;
      this.btSleepModeIsVisible = true;
      this.btInterogationIsVisible = false;
    }
  }

  async closeModal() {
    await this.modalController.dismiss(undefined, undefined, 'modal-status');
  }

  openMap() {
    this.tracerService.selectTracker(this.tracer.id);
    this.closeModal();
    this.router.navigate(['map']);
  }

  async updatePosition() {
    this.tracerService.wakeUp().subscribe();
    this.closeModal();
    this.router.navigate(['map']);

    const toast = await this.toastController.create({
      message: this._translate.instant("Wakes up GPS to get its position."),
      duration: 2000
    });
    toast.present();
  }

  async copyPosition() {
    Clipboard.write({string: this.tracer.latestPosition});

    const toast = await this.toastController.create({
      message: this._translate.instant("Coordinates copied to clipboard."),
      duration: 2000
    });
    toast.present();
  }

  async ring() {
    if(this.tracer.isModelPT32()) {
      var message = this._translate.instant("You can play sound from the GPS for 1 minute.");
      if(this.tracer != null && this.tracer.findIsOn) {
        message += ' ' + this._translate.instant("Currently the GPS is playing a sound");
      }
      var popup = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: this._translate.instant("Playing Sound"),
        subHeader: '',
        message: message,
        buttons: [
          { text: this._translate.instant('Cancel') }, 
          { text: this.tracer != null && this.tracer.findIsOn ? this._translate.instant('Stop sound'): this._translate.instant('Play sound'), cssClass: 'alertControllerButtonBlue', handler: (d) => { this.closeModal(); this.tracerService.playSound().subscribe();} }]
      });
      await popup.present();
    } else {
      var message = this._translate.instant("You can play sound from the GPS for 1 minute. Use the button on the GPS to stop it.");
      var popup = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: this._translate.instant("Playing Sound"),
        subHeader: '',
        message: message,
        buttons: [
          { text: this._translate.instant('Cancel') }, 
          { text: this._translate.instant('Play'), cssClass: 'alertControllerButtonBlue', handler: (d) => { this.closeModal(); this.tracerService.playSound().subscribe();} }]
      });
      await popup.present();
    }
  }


  async light() {
    var message = this._translate.instant("You can flash the GPS light remotely.");
    if(this.tracer != null && this.tracer.lightIsOn) {
      message += ' ' + this._translate.instant("Currently the flash is on");
    }
    var popup = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this._translate.instant("Turn on the light"),
      subHeader: '',
      message: message,
      buttons: [
        { text: this._translate.instant('Cancel') }, 
        { text: this.tracer != null && this.tracer.lightIsOn ? this._translate.instant('Turn off'): this._translate.instant('Flash'), cssClass: 'alertControllerButtonBlue', handler: (d) => { this.closeModal(); this.tracerService.lightOnOff().subscribe();} }]
    });
    await popup.present();
  }

  async bip() {
    this.tracerService.playBip().subscribe();

    const toast = await this.toastController.create({
      message: this._translate.instant("Bip + Vibrator sent."),
      duration: 2000
    });
    toast.present();
  }

  async reboot() {
    this.tracerService.reboot().subscribe();
    this.closeModal();
    this.router.navigate(['map']);
  }

  logs() {
    this.closeModal();
    this.router.navigate(['logs']);
  }

  async bipVibrator() {
    this.tracerService.playBipVibrator().subscribe();

    const toast = await this.toastController.create({
      message: this._translate.instant("Vibrator sent."),
      duration: 2000
    });
    toast.present();
  }

  async bipSound() {
    this.tracerService.playBipSound().subscribe();

    const toast = await this.toastController.create({
      message: this._translate.instant("Bip sent."),
      duration: 2000
    });
    toast.present();
  }

  async test() {
  }

  openPopupLiveStandByMode() {
    this.router.navigate(['mode-change']);
    this.closeModal();
  }

  segmentChangedCB() {
    this._updateracerStatus();
  }

  onNgModelDayDateChange(ev) {
    this.dayDateIonic = ev.detail['value'];
    this.dayDate = parseISO(this.dayDateIonic);
    this._updateracerStatus();
    
    this.dayDateNextIsDisplay()
    this.dayDateBackIsDisplay()
  }

  dayDateBackCB() {
    this.dayDate.setDate(this.dayDate.getDate() - 1);
    this.dayDateIonic = this.dateUtils.toStringIso(this.dayDate);
    this._updateracerStatus();
  }

  dayDateNextCB() {
    this.dayDate.setDate(this.dayDate.getDate() + 1);
    this.dayDateIonic = this.dateUtils.toStringIso(this.dayDate);
    this._updateracerStatus();
  }

  _dayDateNextIsDisplay = false;
  dayDateNextIsDisplay() {
    var d = new Date(this.dayDate);
    d.setDate(d.getDate() + 1);
    this._dayDateNextIsDisplay = d.valueOf() < (new Date()).valueOf();
  }

  _dayDateBackIsDisplay = false;
  dayDateBackIsDisplay() {
    var d = new Date(this.dayDate)
    d.setDate(d.getDate() - 1);
    this._dayDateBackIsDisplay = d.valueOf() > (new Date(2023, 10, 26)).valueOf();
  }


  onNgModelWeekDateChange(ev) {
    this.weekDateIonic = ev.detail['value'];
    this.weekDate = parseISO(this.weekDateIonic);
    this._updateracerStatus();

    this.weekDateNextIsDisplay()
    this.weekDateBackIsDisplay()
  }

  weekDateBackCB() {
    this.weekDate.setDate(this.weekDate.getDate() - 7);
    this.weekDateIonic = this.dateUtils.toStringIso(this.weekDate);
    this._updateracerStatus();
  }

  weekDateNextCB() {
    this.weekDate.setDate(this.weekDate.getDate() + 7);
    this.weekDateIonic = this.dateUtils.toStringIso(this.weekDate);
    this._updateracerStatus();
  }

  _weekDateNextIsDisplay = false
  weekDateNextIsDisplay() {
    var d = new Date(this.weekDate);
    d.setDate(d.getDate() + 7);
    this._weekDateNextIsDisplay = d.valueOf() < (new Date()).valueOf();
  }

  _weekDateBackIsDisplay = false
  weekDateBackIsDisplay() {
    this._weekDateBackIsDisplay = this.weekDate.valueOf() > (new Date(2023, 10, 26)).valueOf();
  }



  onNgModelMonthDateChange(ev) {
    this.monthDateIonic = ev.detail['value'];
    this.monthDate = parseISO(this.monthDateIonic);
    this._updateracerStatus();

    this.monthDateNextIsDisplay()
    this.monthDateBackIsDisplay()
  }

  monthDateBackCB() {
    this.monthDate.setMonth(this.monthDate.getMonth() - 1);
    this.monthDateIonic = this.dateUtils.toStringIso(this.monthDate);
    this._updateracerStatus();
  }

  monthDateNextCB() {
    this.monthDate.setMonth(this.monthDate.getMonth() + 1);
    this.monthDateIonic = this.dateUtils.toStringIso(this.monthDate);
    this._updateracerStatus();
  }

  _monthDateNextIsDisplay = false
  monthDateNextIsDisplay() {
    var d = new Date(this.monthDate);
    d.setMonth(d.getMonth() + 1);
    this._monthDateNextIsDisplay = d.valueOf() < (new Date()).valueOf();
  }

  _monthDateBackIsDisplay = false
  monthDateBackIsDisplay() {
    this._monthDateBackIsDisplay = this.monthDate.valueOf() > (new Date(2023, 10, 26)).valueOf();
  }
}
