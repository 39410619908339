import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service'
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  { path: '', redirectTo: 'map', pathMatch: 'full' },
  { path: 'apps.canigps.fr', redirectTo: 'map', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'map', loadChildren: () => import('./map/map.module').then(m => m.MapPageModule) },
  { path: 'animal-add-set-to-tracer/:tracerId', loadChildren: () => import('./animal/animal-add/animal-add.module').then(m => m.AnimalAddPageModule) },
  { path: 'animal-add', loadChildren: () => import('./animal/animal-add/animal-add.module').then(m => m.AnimalAddPageModule) },
  { path: 'animal-update/:animalId', loadChildren: () => import('./animal/animal-update/animal-update.module').then(m => m.AnimalUpdatePageModule) },
  { path: 'animal-list', loadChildren: () => import('./animal/animal-list/animal-list.module').then(m => m.AnimalListPageModule) },
  { path: 'tracer-update/:tracerId', loadChildren: () => import('./tracer/tracer-update/tracer-update.module').then(m => m.TracerUpdatePageModule) },
  { path: 'tracer-add', loadChildren: () => import('./tracer/tracer-add/tracer-add.module').then(m => m.TracerAddPageModule) },
  { path: 'tracer-list', loadChildren: () => import('./tracer/tracer-list/tracer-list.module').then(m => m.TracerListPageModule) },
  { path: 'tracer-empty', loadChildren: () => import('./tracer/tracer-empty/tracer-empty.module').then(m => m.TracerEmptyPageModule) },
  { path: 'fencing-add', loadChildren: () => import('./fencing/fencing-add/fencing-add.module').then(m => m.FencingAddPageModule) },
  { path: 'fencing-update/:fencingId', loadChildren: () => import('./fencing/fencing-update/fencing-update.module').then(m => m.FencingUpdatePageModule) },
  { path: 'fencing-list', loadChildren: () => import('./fencing/fencing-list/fencing-list.module').then(m => m.FencingListPageModule) },
  { path: 'activity-list', loadChildren: () => import('./activity/activity-list/activity-list.module').then(m => m.ActivityListPageModule) },
  { path: 'activity-live/:activityId', loadChildren: () => import('./activity/activity-live/activity-live.module').then(m => m.ActivityLivePageModule) },
  { path: 'activity-dashboard/:activityId', loadChildren: () => import('./activity/activity-dashboard/activity-dashboard.module').then(m => m.ActivityDashboardPageModule) },
  { path: 'mode-change', loadChildren: () => import('./mode-change/mode-change.module').then(m => m.ModeChangePageModule) },
  {
    path: 'shop-list',
    loadChildren: () => import('./shop/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'shop-product/:productsId',
    loadChildren: () => import('./shop/product/product.module').then( m => m.ProductPageModule)
  },
  {
    path: 'share/:shareCode',
    loadChildren: () => import('./share/shareMap/shareMap.module').then( m => m.ShareMapPageModule)
  },
  {
    path: 'account-create',
    loadChildren: () => import('./account/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'account-update',
    loadChildren: () => import('./account/update/update.module').then( m => m.UpdatePageModule)
  },
  {
    path: 'shop-redirect',
    loadChildren: () => import('./shop/redirect/redirect.module').then( m => m.RedirectPageModule)
  },
  {
    path: 'status',
    loadChildren: () => import('./status/status.module').then( m => m.StatusPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'stats',
    loadChildren: () => import('./stats/stats.module').then( m => m.StatsPageModule)
  },
  {
    path: 'convert-to-activity',
    loadChildren: () => import('./convert-to-activity/convert-to-activity.module').then( m => m.ConvertToActivityPageModule)
  },
  {
    path: 'shop/subscription',
    loadChildren: () => import('./shop/subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    path: 'share',
    loadChildren: () => import('./share/share.module').then( m => m.SharePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'account-delete',
    loadChildren: () => import('./account/delete/delete.module').then( m => m.DeletePageModule)
  },
  {
    path: 'sensor-list',
    loadChildren: () => import('./sensor/sensor-list/sensor-list.module').then( m => m.SensorListPageModule)
  },
  {
    path: 'sensor-add',
    loadChildren: () => import('./sensor/sensor-add/sensor-add.module').then( m => m.SensorAddPageModule)
  },
  {
    path: 'sensor-update/:sensorId',
    loadChildren: () => import('./sensor/sensor-update/sensor-update.module').then( m => m.SensorUpdatePageModule)
  },
  {
    path: 'sensor-activity-status',
    loadChildren: () => import('./sensor/sensor-activity-status/sensor-activity-status.module').then( m => m.SensorActivityStatusPageModule)
  },
  {
    path: 'strava-login',
    loadChildren: () => import('./strava/strava-login/strava-login.module').then( m => m.StravaLoginPageModule)
  },
  {
    path: 'strava-login;:animalId',
    loadChildren: () => import('./strava/strava-login/strava-login.module').then( m => m.StravaLoginPageModule)
  },
  {
    path: 'documentations',
    loadChildren: () => import('./documentations/documentations.module').then( m => m.DocumentationsPageModule)
  },
  {
    path: 'configuration',
    loadChildren: () => import('./configuration/configuration.module').then( m => m.ConfigurationPageModule)
  },
  {
    path: 'tracer-associate-sim',
    loadChildren: () => import('./tracer/tracer-associate-sim/tracer-associate-sim.module').then( m => m.TracerAssociateSimPageModule)
  },
  {
    path: 'shop-cart',
    loadChildren: () => import('./shop/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'shop-cart/:cartId/:state',
    loadChildren: () => import('./shop/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'shop-cart-subscriptionForTracerId/:tracerId',
    loadChildren: () => import('./shop/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'shop-cart/:cartId/:state',
    loadChildren: () => import('./shop/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'logs',
    loadChildren: () => import('./logs/logs.module').then( m => m.LogsPageModule)
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule {}




