import { Component, Inject, OnInit, QueryList, ViewChildren, ViewChild, NgZone } from '@angular/core';
import { Platform, NavController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { StatusPage } from './status/status.page';
import { HistoryPage } from './history/history.page';
import { AlertService } from './services/alert.service';
import { ActivityService } from './services/activity.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from './../environments/environment';
import { TracerService } from './services/tracer.service';
import { AnimalService } from './services/animal.service';
import { FencingService } from './services/fencing.service';
import { MenuController } from '@ionic/angular';
import { AlertController, IonRouterOutlet } from '@ionic/angular';
import { ChangeDetectorRef } from '@angular/core'
import { WebsocketService } from './services/websocket.service';
import { UserService } from './services/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { BackButtonEvent } from '@ionic/core';
import { DateUtilsService } from './services/date-utils.service';
import { EventsService } from './services/events.service';
import { Location } from "@angular/common";
import { App } from '@capacitor/app';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { IonModal } from '@ionic/angular';
import { URLOpenListenerEvent } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';

import { register } from 'swiper/element/bundle';
register();

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor, registerWebPlugin } from '@capacitor/core';

//import { Plugins } from '@capacitor/core';
//const { App } = Plugins;


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('modalLangSelector', { static: true }) modalLangSelector!: IonModal;

  tracerCurrent = null;
  tracerCurrentIconAvatarBase64 = null;

  tracers = [];
  pushNotificationsTokenValue = null;
  displayTabs: boolean = true;
  closed$ = new Subject<any>();

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList < IonRouterOutlet > ;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  displayTracerAssociateSIM = false;

  platformIsReady = false

  keyboardIsShow = false;

  languages = [
    {'id': '5', 'code': 'fr-FR', 'code_short': 'fr', 'name': 'France', 'prefix': '<span class="fi fi-fr"></span>'},
    {'id': '0', 'code': 'de-AT', 'code_short': 'de', 'name': 'Austria', 'prefix': '<span class="fi fi-at"></span>'},
    {'id': '1', 'code': 'fr-BE', 'code_short': 'fr', 'name': 'Belgium (French)', 'prefix': '<span class="fi fi-be"></span>'},
    {'id': '2', 'code': 'nl-BE', 'code_short': 'nl', 'name': 'Belgium (Dutch)', 'prefix': '<span class="fi fi-be"></span>'},
    {'id': '3', 'code': 'da-DK', 'code_short': 'da', 'name': 'Denmark', 'prefix': '<span class="fi fi-dk"></span>'},
    {'id': '4', 'code': 'fi-FI', 'code_short': 'fi', 'name': 'Finland', 'prefix': '<span class="fi fi-ie"></span>'},
    {'id': '6', 'code': 'de-DE', 'code_short': 'de', 'name': 'Germany', 'prefix': '<span class="fi fi-de"></span>'},
    {'id': '7', 'code': 'en-IE', 'code_short': 'en', 'name': 'Finland (English)', 'prefix': '<span class="fi fi-ie"></span>'},
    {'id': '8', 'code': 'fi-FI', 'code_short': 'fi', 'name': 'Ireland', 'prefix': '<span class="fi fi-fi"></span>'},
    {'id': '9', 'code': 'it-IT', 'code_short': 'it', 'name': 'Italy', 'prefix': '<span class="fi fi-it"></span>'},
    {'id': '10', 'code': 'nl-NL', 'code_short': 'nl', 'name': 'Netherlands', 'prefix': '<span class="fi fi-nl"></span>'},
    {'id': '11', 'code': 'pt-PT', 'code_short': 'pt', 'name': 'Portugal', 'prefix': '<span class="fi fi-pt"></span>'},
    {'id': '12', 'code': 'es-ES', 'code_short': 'es', 'name': 'Spain', 'prefix': '<span class="fi fi-es"></span>'},
    {'id': '13', 'code': 'sv-SE', 'code_short': 'sv', 'name': 'Sweden', 'prefix': '<span class="fi fi-se"></span>'},
    {'id': '14', 'code': 'en-GB', 'code_short': 'en', 'name': 'Great Britain', 'prefix': '<span class="fi fi-gb"></span>'},
    {'id': '15', 'code': 'en-US', 'code_short': 'en', 'name': 'United States Of America', 'prefix': '<span class="fi fi-us"></span>'},
    {'id': '16', 'code': 'cs-CZ', 'code_short': 'cs', 'name': 'Česky', 'prefix': '<span class="fi fi-cz"></span>'},
  ]
  currentLang = null;

  setLangFromNavigator() {
    var userLangNavigator = navigator.language
    var lang = null;
    var found = false;

    for(var l in this.languages) {
      if(this.languages[l]['code'].toLowerCase() == userLangNavigator.toLowerCase()) {
        lang = this.languages[l]
        found = true;
        break;
      }
    }
    if(!found) {
      userLangNavigator = navigator.language.split('-')[0];
      for(var l in this.languages) {
        if(this.languages[l]['code_short'].toLowerCase() == userLangNavigator.toLowerCase()) {
          lang = this.languages[l]
          found = true;
          break;
        }
      }
    }
    if(!found) {
      for(var _l in this.languages) {
        var __l = this.languages[_l];
        if(__l['code'] == 'en-GB') {
          lang = __l;
          break;
        }
      }
      lang = this.languages[14]
    }

    this.setLang(lang, true)
  }

  setLang(lang, save) {
    this.translate.setDefaultLang('en');
    this.translate.use(lang['code_short']);
    environment.API_URL = environment.API_URL_CLEAR + lang['code_short'] + '/'
    this.document.documentElement.lang = lang['code_short'];
    this.currentLang = lang;

    if(save && this.storageService != null && this.storageService.configuration != null) {
      this.storageService.configuration.language = lang;
      this.storageService.saveConfiguration();
    }
    
    if(this.authService.isLoggedIn) {
      var headers = new HttpHeaders({
        'Authorization': this.authService.getHttpHeaders()
      });

      this.http.get(environment.API_URL + 'accounts/ws/' + this.currentLang['code_short'] + '/setLanguageCode/', {headers : headers}).subscribe(async (response: any[]) => {
      });
    }
  }
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private navCtrl: NavController,
    private alertService: AlertService,    
    private translate: TranslateService,
    private http: HttpClient, 
    private animalService: AnimalService,
    private tracerService: TracerService,
    private fencingService: FencingService,
    public menuCtrl: MenuController,
    private ref: ChangeDetectorRef,
    private websocketService: WebsocketService,
    private userService: UserService,
    private router: Router,
    public dateUtils: DateUtilsService,
    public events: EventsService,
    private location: Location,
    private alertController: AlertController,
    public modalController: ModalController,
    public sanitizer: DomSanitizer,
    public storageService: StorageService,
    public activityService: ActivityService,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ) {

    var nbBackground = 7
    var div = document.getElementById("divBodyLeft")
    var r1 = Math.floor(Math.random() * nbBackground + 1)
    div.classList.add("b" + r1);
    var div = document.getElementById("divBodyRight")
    var r2 = Math.floor(Math.random() * nbBackground + 1)
    while(r2 == r1)
      r2 = Math.floor(Math.random() * nbBackground + 1)
    div.classList.add("b" + r2);

    Keyboard.addListener('keyboardWillShow', info => {
      var elt = document.getElementById("body")
      elt.classList.add("keyboard-is-open");
    });
    Keyboard.addListener('keyboardWillHide', () => {
      var elt = document.getElementById("body")
      elt.classList.remove("keyboard-is-open");
    });

    this.setLangFromNavigator();
    this.languages.sort((a,b) => a['name'].localeCompare(b['name']))

    this.initializeApp();

    this.platform.ready().then(() => {
      this.platformIsReady = false
      this.platform.backButton.subscribeWithPriority(
        10000,
        () => { 
          if(!this.authService.isLoggedIn) {
            this.tracerService.EmptyTracers();
            this.router.navigate(['login']);
            
          }
          else
            this.navCtrl.back();
        }
      );
    });

    this.authService.loginStatus$.subscribe((isLoggedIn) => {
      if(this.authService.isLoggedIn) {
        this.displayTracerAssociateSIM = this.authService.username == 'jonathan.atton@gmail.com';
      }
    });

    this.updateMenuTracers();

    this.tracerService.tracerListChange$.subscribe(() => {
      this.animalService.updateTracers();
      this.updateTracerCurrent();
      this.updateMenuTracers();

      if(this.authService.redirectUrl && this.authService.redirectUrl != 'login' && this.authService.redirectUrl != '/login' && this.authService.redirectUrl != window.location.pathname && this.authService.isLoggedIn) {
        console.log('REDIRECT. ' + this.authService.redirectUrl)
        var redirectTo = this.authService.redirectUrl
        this.authService.redirectUrl = null
        //this.router.navigate([this.authService.redirectUrl]);
        window.location.href = redirectTo;
      }

      this.displayTracerAssociateSIM = this.authService.username == 'jonathan.atton@gmail.com';
    })
    this.tracerService.tracerAvatarListChange$.subscribe(() => {
      //this.animalService.updateTracers();
      //this.updateMenuTracers();
    })
    this.tracerService.tracerCurrentChange$.subscribe(() => {
      this.animalService.updateTracers();
      this.updateTracerCurrent();
      this.updateMenuTracers();
    })
    this.animalService.animalListChange$.subscribe(() => {
      this.animalService.updateTracers();
      this.updateMenuTracers();
    })
    this.animalService.animalAvatarListChange$.subscribe(() => {
      this.animalService.updateTracers();
      this.updateTracerCurrent();
      this.updateMenuTracers();
    })
    this.storageService.configurationUpdated$.subscribe(() => {

      if(this.storageService.configuration.language != null && this.storageService.configuration.language['code_short'] != null) {
        this.setLang(this.storageService.configuration.language, false)
      } else {
        this.setLangFromNavigator();
      }

      var act = this.activityService.GetActivityTypes()
      if(act != null)
        act.subscribe(() => {});
    })

    this.websocketService.websocketStatusUpdate$.subscribe(() => {
      this.updateMenuTracers();
    });

    //this.setOverflowTabButton();
  }

  /*
  private setOverflowTabButton() {
      const all = document.querySelectorAll('.tab-has-icon');
      var found: boolean = false;

      if(document.getElementById('ion-tab-button-avatar') != null) {
      var w = document.getElementById('ion-tabs').clientWidth;
        if(w > 0) {
          found = true;
          setTimeout(() => {
            document.getElementById('ion-tabs').style.width = String(w - 200) + "px";
            document.getElementById('ion-tabs').style.width = String(w) + "px";
          }, 150);
        }
      }

      if(!found)
      {
        setTimeout(() => {
          this.setOverflowTabButton();
        }, 1);
      }
  }
  */


  ionViewDidEnter() {
  }

  displayShopMenu() {
    return true;
  }

  //http://localhost:8100/strava-login;animalId=37


  tabBarsSelectItem(url) {
    var tabs = document.querySelectorAll('#canicompet-tabs a');
    if(tabs.length == 0) {
      setTimeout(() => { this.tabBarsSelectItem(url); }, 1000)
    }

    if(url == '/' || url == '')
      url = '/map'

    for(let i = 0; i< tabs.length; i++) {
      var t = tabs[i].getAttribute('tab')
      tabs[i].classList.remove('selected')
      if(url.startsWith('/' + t) || url.startsWith(t)) {
        tabs[i].classList.add('selected')
      }
    }
  }

  initializeApp() {

    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))  
    .subscribe((event: NavigationEnd) => {
      this.tabBarsSelectItem(event.url)
    });

      this.events.subscribe('loginPageActivate', () => {
        this.displayTabs = false;
      })
      this.events.subscribe('loginPageDeActivate', () => {
        this.displayTabs = true;
      })

      this.authService.loginStatus$.subscribe((isLoggedIn) => {
        if(!isLoggedIn){
          if(!document.URL.includes('share')) {
            this.tracerService.EmptyTracers();

            setTimeout(() => {
              if(!this.authService.redirectUrl) {
                this.authService.redirectUrl = window.location.pathname;
                console.log('SET REDIRECT1 ' + this.authService.redirectUrl)
              }
              this.router.navigate(['login']);
            }, 300);

          }
        }
        else {

          this.fencingService.GetFencings().subscribe(() => {});
          this.animalService.GetAnimals().subscribe(() => {});
          this.tracerService.GetTracers().subscribe(() => {});

          if(this.pushNotificationsTokenValue != null) {
            var type: string = this.platform.is('ios') ? 'ios': this.platform.is('android') ? 'android': 'web';
            this.userService.setDeviceId(this.pushNotificationsTokenValue, type).subscribe((data) => {
            })
          }

          var headers = new HttpHeaders({
            'Authorization': this.authService.getHttpHeaders()
          });
          this.http.get(environment.API_URL + 'accounts/ws/' + this.currentLang['code_short'] + '/setLanguageCode/', {headers : headers}).subscribe(async (response: any[]) => {
          });
        }
      })


    this.tracerService.tracerListChange$.subscribe((isLoggedIn) => {
      if(this.authService.isLoggedIn && this.tracerService.tracers.length == 0){
        this.router.navigate(['tracer-empty']);
      }
    })

    this.platform.ready().then(() => {
      this.platformIsReady = true
      //this.statusBar.styleDefault();
      // Commenting splashScreen Hide, so it won't hide splashScreen before auth check
      //this.splashScreen.hide();
      if(!document.URL.includes('share')) {
        this.authService.getToken();
      }
    });
  }

  async ngOnInit() {

      //Deep link
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
          this.zone.run(() => {
              const slug = event.url.split(".fr").pop();
              if (slug) {
                  this.router.navigateByUrl(slug);
              }
          });
      });

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      takeUntil(this.closed$)
    ).subscribe(event => {
      if (event['url'] === '/login' || event['url'] === '/account-create') {
        this.displayTabs = false; // <-- hide tabs on specific pages
      }
      else {
        this.displayTabs = true;
      }
    });

    this.initPush()
    this.authService.loginStatus$.subscribe(async (isLoggedIn) => {
      this.initPush();
    });
  }

  // When Logout Button is pressed 
  logout() {
    this.authService.logout().subscribe(
      data => {
        //this.alertService.presentToast(data['message']);        
      },
      error => {
        console.log(error);
      },
      () => {
        this.router.navigate(['login']);
      }
    );
  }

  initPush() {

    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
    if (isPushNotificationsAvailable) {

      PushNotifications.createChannel({
        id: "fcm_default_channel",
        name: "fcm_default_channel",
        importance: 5,
        visibility: 1,
        lights: true,
        vibration: true,
      })
      .then(() => {
      })
      .catch((error) => {
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          console.log('Push registration success, token: ' + token.value);
          this.pushNotificationsTokenValue = token.value;

          if(this.authService.isLoggedIn) {
            var type: string = this.platform.is('ios') ? 'ios': this.platform.is('android') ? 'android': 'web';
            this.userService.setDeviceId(this.pushNotificationsTokenValue, type).subscribe((data) => {
            })
          }
        }
      );

      
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        async (notification: PushNotificationSchema) => {
          var json = JSON.stringify(notification);
          console.log('Push received: ' + json);

          var title: string;
          var msg: string;
          if(notification['data']['aps'] != null) { //IOS
            title = notification['data']['aps']['alert']['title'];
            msg = notification['data']['aps']['alert']['body'];
          } else { //Android
            title = notification['title'];
            msg = notification['body'];
          }

          var action = notification['data']['action'] || null;
          var displayToast = true;

          if (action == 'ShareWithUser' || action == 'ShareRemove') {
            this.tracerService.GetTracers().subscribe(() => {
            });
          }

          if(displayToast) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: title,
                subHeader: '',
                message: msg,
                buttons: [this.translate.instant('Close')]
            });
            await alert.present();
          }
        }
      );
      
      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(notification));
        }
      );
        

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
        console.log('result.receive ' + result.receive);
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });
    }


  }

  ngAfterViewInit() {
  }

  updateMenuTracers() {
    //On commence par regarder si les traceurs ont changé pour voir si il s'agit d'une grosse mise à jour ou non
    var listChanged: boolean = false;
    var i: number = 0;

    if(this.tracerService.tracers.length == 0) {
      listChanged = true;
    }

    var tracersSorted = this.tracerService.tracers.sort((a,b) => (a.animal != null ? a.animal.name : a.code).localeCompare(b.animal != null ? b.animal.name : b.code));

    //On compare la liste des tracers avec la liste présente dans le menu pour détecte run nouveau traceur, une suppression ...
    for(var tracer in tracersSorted) {
      var t = this.tracerService.tracers[tracer];

      if(t.isHidden)
        continue;

      if(i >= this.tracers.length || t.id != this.tracers[i].id || t.isHidden != this.tracers[i].isHidden)
      {
        listChanged = true;
        break;
      }

      i++;
    }

    //On regarde si un tracer caché est visible dabs le menu
    for(var tracer in tracersSorted) {
      var t = this.tracerService.tracers[tracer];

      if(!t.isHidden)
        continue; 

      for(var ti in this.tracers) {
        var _ti = this.tracers[ti]
        if(_ti.id == t.id) {
          listChanged = true;
          break;
        }
      }

      if(listChanged)
        break;
    }

    if(listChanged)
    {
      //console.log("Menu: full update")
      this.tracers = []

      for(var tracer in tracersSorted) {
        var t = this.tracerService.tracers[tracer];
        if(t.isHidden)
          continue;
        
        if(t.animal != null)
          this.tracers.push({title : t.animal.name, id: t.id, isHidden: t.isHidden, icon: t.getIconAvatarUrlLocal(), tracer: t, 
            statusGPSIcon: t.getStatusGPSIcon(), statusGSMIcon: t.getStatusGSMIcon(), statusBatteryIcon: t.getBatteryIconHorizontal(),
            statusBatteryLevel: t.getStatus() != 'error' ? String(t.voltageLevel) : '??', statusGPSIonChip: t.getStatusGPS() ? 'success': 'danger',
            statusGSMIonChip: t.getStatusGSM() ? 'success': 'danger', statusBatteryIonChip: t.voltageLevel > 32 && t.getStatus() != 'error' ? 'success': 'danger',
            isShare: t.isShare});
        else
          this.tracers.push({title : String(this.translate.instant("Not associated")) + " - " + t.code, id: t.id, isHidden: t.isHidden, icon: t.getIconAvatarUrlLocal(), tracer: t, 
            statusGPSIcon: t.getStatusGPSIcon(), statusGSMIcon: t.getStatusGSMIcon(), statusBatteryIcon: t.getBatteryIconHorizontal(),
            statusBatteryLevel: t.getStatus() != 'error' ? String(t.voltageLevel) : '??', statusGPSIonChip: t.getStatusGPS() ? 'success': 'danger',
            statusGSMIonChip: t.getStatusGSM() ? 'success': 'danger', statusBatteryIonChip: t.voltageLevel > 32 && t.getStatus() != 'error' ? 'success': 'danger',
            isShare: t.isShare});
      }
    }
    else 
    {
      //console.log("Menu: partial update")
      i = 0;
      for(var tracer in tracersSorted) {
        var t = this.tracerService.tracers[tracer];
        
        if(t.isHidden)
          continue;

        if(t.animal != null)
          this.tracers[i].title = t.animal.name;
        else 
          this.tracers[i].title = String(this.translate.instant("Not associated")) + " - " + t.code;
          
        this.tracers[i].icon = t.getIconAvatarUrlLocal();
        this.tracers[i].tracer = t;
        this.tracers[i].isHidden = t.isHidden;
        this.tracers[i].isShare = t.isShare;

        this.tracers[i].statusGPSIcon = t.getStatusGPSIcon();
        this.tracers[i].statusGSMIcon = t.getStatusGSMIcon();
        this.tracers[i].statusBatteryIcon = t.getBatteryIconHorizontal();
        this.tracers[i].statusBatteryLevel = t.getStatus() != 'error' ? String(t.voltageLevel) : '??';
        this.tracers[i].statusGPSIonChip = t.getStatusGPS() ? 'success': 'danger';
        this.tracers[i].statusGSMIonChip = t.getStatusGSM() ? 'success': 'danger';
        this.tracers[i].statusBatteryIonChip = t.voltageLevel > 32 && t.getStatus() != 'error' ? 'success': 'danger';

        i++;
      }
    }
    this.ref.markForCheck();
  }

  selectTracker(trackerId) {
    this.tracerService.selectTracker(trackerId);
    this.menuCtrl.close();
    this.router.navigate(['map']);
  }

  async selectCurrentTracker() {
    const modal = await this.modalController.create({
      component: StatusPage,
      id: 'modal-status',
      componentProps: {
      }
    });
    return await modal.present();
  }
  
  OpenTracerList() {
    this.menuCtrl.close();
    this.router.navigate(['tracer-list']);
  }

  CordovaAvailable() {
    return this.platform.is('cordova');
  }

  OpenShop() {
    //if (this.platform.is('cordova') || (this.authService.isLoggedIn && this.authService.username == 'jonathan.atton@gmail.com')) {
      this.menuCtrl.close();
      this.router.navigate(['shop-list']);
      //this.router.navigate('shop-redirect');
    //} else {
    //  window.open('https://canigps.fr', "_blank");
    //}
  }

  OpenContact() {
    this.menuCtrl.close();
    this.router.navigate(['contact']);
  }

  OpenTracerAdd() {
    this.menuCtrl.close();
    this.router.navigate(['tracer-add']);
  }

  OpenAnimalList() {
    this.menuCtrl.close();
    this.router.navigate(['animal-list']);
  }

  OpenAnimalAdd() {
    this.menuCtrl.close();
    this.router.navigate(['animal-add']);
  }

  OpenFencingList() {
    this.menuCtrl.close();
    this.router.navigate(['fencing-list']);
  }

  OpenFencingAdd() {
    this.menuCtrl.close();
    this.router.navigate(['fencing-add']);
  }

  OpenSensorsList() {
    this.menuCtrl.close();
    this.router.navigate(['sensor-list']);
  }

  OpenProfile() {
    this.menuCtrl.close();
    this.router.navigate(['account-update']);
  }

  displayConfiguration() {
    this.menuCtrl.close();
    this.router.navigate(['configuration']);
  }


  OpenTracerAssociateSIM() {
    this.menuCtrl.close();
    this.router.navigate(['tracer-associate-sim']);
  }



  updateTracerCurrent() {
    this.tracerCurrent = this.tracerService.getCurrentTracer();
    if(this.tracerCurrent)
      this.tracerCurrentIconAvatarBase64 = this.tracerCurrent.getIconAvatarUrlLocal()
    else
      this.tracerCurrentIconAvatarBase64 = null;
    this.ref.markForCheck();
  }

  displayDocumentations() {
    this.menuCtrl.close();
    window.open('https://doc.canicompet.com/canigps', "_blank");
  }


  langSelectionChanged(event) {
    for(var _l in this.languages) {
      var l = this.languages[_l];
      if(l['id'] == event) {
        this.setLang(l, true);
        break;
      }
    }
    this.modalLangSelector.dismiss();
    this.menuCtrl.close();

     let currentUrl = this.router.url;
       this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
       this.router.navigate([currentUrl]);
     });
  }

  langSearchChanged(event) {
  }

 }

